import { ClimateControlStatus } from '@api/enums/ClimateControlStatus';
import { useSiteContext } from '@pages/site/SiteProvider';
import Overview from './components/overview/Overview';
import Setup from './components/setup/Setup';

const ClimateControlV2 = () => {
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();

  if (siteClimateControl?.status === ClimateControlStatus.Setup) {
    return (
      <Setup
        siteClimateControl={siteClimateControl}
        refreshSiteClimateControl={refreshSiteClimateControl}
      />
    );
  }

  return (
    <Overview
      siteClimateControl={siteClimateControl}
      refreshSiteClimateControl={refreshSiteClimateControl}
    />
  );
}

export default ClimateControlV2