import UtilityTabs from './UtilityTabs';
import { Title } from '@components/core/Title';
import { useTranslation } from 'react-i18next';
import { useApiState } from '@hooks/useApiState';
import SiteUtilitiesConsumptionGetQuery from '@api/queries/metrics/SiteUtilitiesConsumptionGetQuery';
import LoadingWidget from '@components/core/LoadingWidget';
import { Card } from '@components/core/Card';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import CSVExport from './CSVExport';
import { UtilityConsumptionDto } from '@api/models/UtilityConsumptionDto';
import { MetricType } from '@api/enums/MetricType';
import { createDataPoint, createDataset, getConsumptionWidgetConfig, getTargetYTD, sortUtilities } from './helpers';
import { useSiteContext } from '@pages/site/SiteProvider';
import { SiteMetadata } from '@api/models/SiteMetadata';
import { useEffect, useState } from 'react';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { LocalisationType } from '@i18n//localisation';
import UtilitiesSummaryPreview from './UtilitiesSummaryPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';

export type UtilityDataMeterCategory = {
  meterCategory: string | null;
  currentYearTotals: number[];
  previousYearTotals: number[];
};

export type UtilityData = {
  metricType: MetricType;
  unit: string;
  total: number;
  target: number;
  targetYTD: number;
  monthlyTargets: number[];
  meterCategories: UtilityDataMeterCategory[];
  isGasWidget: boolean;
  hasConversion: boolean;
  monthlyTotalsThisYear: number[];
  monthlyTotalsLastYear: number[];
};

const prepareData = (data: UtilityConsumptionDto[], conversions: { [key: string]: string }, localisation: LocalisationType, siteMetadata?: SiteMetadata): UtilityData[] => {
  const utilities: UtilityData[] = sortUtilities(data).map(utility => {
    const config = getConsumptionWidgetConfig(utility.metricType, localisation, siteMetadata?.gasCalorificValue);
    const applyConversion = !!conversions[utility.metricType];

    return {
      metricType: utility.metricType,
      unit: applyConversion && config.conversion ? config.conversion.unit : config.unit,
      total: createDataPoint(utility.total, config, applyConversion),
      target: createDataPoint(utility.target, config, applyConversion),
      targetYTD: getTargetYTD(utility, config, applyConversion),
      monthlyTargets: createDataset(utility.monthlyTargets, config, applyConversion),
      meterCategories: utility.meterCategories.map(x => ({
        meterCategory: x.meterCategory,
        currentYearTotals: createDataset(x.currentYearTotals, config, applyConversion),
        previousYearTotals: createDataset(x.previousYearTotals, config, applyConversion)
      })),
      isGasWidget: utility.metricType === MetricType.GasVolumeDelta,
      hasConversion: !!config.conversion,
      monthlyTotalsThisYear: createDataset(utility.monthlyTotalsForUtilityThisYear, config, applyConversion),
      monthlyTotalsLastYear: createDataset(utility.monthlyTotalsForUtilityLastYear, config, applyConversion),
    };
  });

  return utilities;
};

const UtilitiesSummary = () => {
  const { t } = useTranslation();
  const { site, siteMetadata } = useSiteContext();
  const [data, setData] = useState<UtilityData[]>([]);
  const [conversions, setConversions] = useState<{ [key: string]: string }>({});
  const { localisation } = useLocalisation();
  const { data: dto, loading } = useApiState({
    query: new SiteUtilitiesConsumptionGetQuery(site.id)
  }, [site]);

  useEffect(() => {
    if (!dto) {
      return;
    }

    setData(prepareData(dto, conversions, localisation, siteMetadata));
  }, [dto, siteMetadata, conversions, localisation]);

  if (!loading && data.length === 0) {
    return <UtilitiesSummaryPreview />;
  }

  return (
    <Card noPadding style={{ padding: 32 }}>
      <FlexColumn>
        <FlexRow>
          <Title text={t('Utilities', { ns: 'common' })} />

          <InfoIconWrapper>
            <Tooltip
              placement={TooltipPlacement.Bottom}
              fixedPosition={{ x: 14, y: 20 }}
              content={
                <TooltipContent>
                  {t('SiteOverview.ConsumptionTooltip', { ns: 'molecules' })}
                </TooltipContent>
              }
            >
              <InfoIcon
                icon={regular('circle-info')}
              />
            </Tooltip>
          </InfoIconWrapper>

          {data.length > 0 &&
            <CSVExport
              data={data}
              loading={loading}
            />
          }
        </FlexRow>

        {loading &&
          <LoadingWidget
            icon={duotone('chart-simple')}
            iconColor='#FFB500'
            label={t('SiteOverview.LoadingUtilitySummary', { ns: 'molecules' })}
            styles={{ height: 320, justifyContent: 'center' }}
            iconStyles={{ width: 30 }}
          />
        }

        {!loading &&
          <UtilityTabs
            data={data}
            conversions={conversions}
            onSetConversions={setConversions}
          />
        }
      </FlexColumn>
    </Card>
  );
};

export default UtilitiesSummary;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 530px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
`;

const InfoIconWrapper = styled.div`
  margin: -2px 5px 0 3px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  width: 275px;
  padding: 10px;
  font-size: 12px;
`;