import { useTranslation } from 'react-i18next';
import { Title } from '@components/core/Title';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { ClimateControlSettingsWidget } from '../climate-control-settings-widget/ClimateControlSettingsWidget';
import styled from 'styled-components';
import { TRVControlMode } from '@api/enums/TRVControlMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { alphabeticalSort } from '@utils/StringUtils';
import FlexRow from '@components/core/FlexRow';
import { Card } from '@components/core/Card';

interface ISiteClimateControlProps {
  siteClimateControl: SiteClimateControlDto;
  refresh: () => void;
  onHasUnsavedChanges: (state: boolean) => void;
}

const SiteClimateControl = ({ siteClimateControl, refresh, onHasUnsavedChanges }: ISiteClimateControlProps) => {
  const { t } = useTranslation();

  const spacesWithNoExternalSensor = siteClimateControl.spaceClimateControls
    .filter(x => (x.controlMode ?? x.siteClimateControl.controlMode) === TRVControlMode.External && !x.temperatureMonitoringDeviceId)
    .sort((a, b) => alphabeticalSort(a.spaceName, b.spaceName));

  const showEmailWarning = siteClimateControl.alertEmails.length === 0;

  return (
    <Card>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <Title
          text={t('ClimateControl.Temperature', { ns: 'molecules' })}
          subtitle={{ text: t('ClimateControl.SiteTemperature', { ns: 'molecules' }) }}
        />

      </FlexRow>

      <Wrapper>
        <ClimateControlSettingsWidget
          siteClimateControl={siteClimateControl}
          refresh={refresh}
          onHasUnsavedChanges={onHasUnsavedChanges}
        />

        <div>
          {showEmailWarning &&
            <Warning to={'alert-settings'}            >
              <WarningIcon icon={solid('exclamation-triangle')} />
              {t('ClimateControl.NoAlertEmail', { ns: 'molecules', })}
            </Warning>
          }

          {spacesWithNoExternalSensor?.map((space) => (
            <Warning
              key={space.id}
              to={`./../building/${space.buildingId}/floor/${space.floorId}/space/${space.spaceId}`}
            >
              <WarningIcon icon={solid('exclamation-triangle')} />
              {t('ClimateControl.NoExternalSensorMessage', { ns: 'molecules', spaceName: space.spaceName })}
            </Warning>
          ))}
        </div>
      </Wrapper>
    </Card>
  );
};

export default SiteClimateControl;

const Wrapper = styled.div`
  margin-top: 75px;
`;

const Warning = styled(Link) <{ isVisible?: boolean }>`
  font-size: 12px;
  color: ${p => p.theme.palette.red} !important;
  padding: 0 5px;
  margin-top: 8px;

  display: flex;
  align-items: center;

  :first-child {
    margin-top: 20px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.red};
  margin: -2px 8px 0 0;
`;
