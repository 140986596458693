import { EsgExplorerMetricsDto } from '@api/models/EsgExplorerMetricsDto';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from '@components/core/Title';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { Card } from '@components/core/Card';
import { useApi } from '@hooks/useApi';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EsgExplorerConfiguration, EsgExplorerFilter, EsgExplorerFilterType } from '@api/models/EsgExplorerConfiguration';
import EsgExplorerDataGetQuery from '@api/queries/esg-explorer/EsgExplorerDataGetQuery';
import { FallbackGasCalorificValue } from '@src/constants/FallbackGasCalorificValue';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import useSiteSpaceTypes from '@hooks/useSiteSpaceTypes';
import { OccupancyStatus } from '@api/enums/OccupancyStatus';
import OutlierConfig from './OutlierConfig';
import OutlierExport from './OutlierExport';
import EsgMetricType from '@api/enums/EsgMetricType';
import { DefaultTimeRanges, TimeRangeType } from './TimeRangeSelect';
import BetaLabel from '@components/core/BetaLabel';
import { Button } from '@components/core/Button';
import { useModal } from '@hooks/useModal';
import FeedbackModal from '@components/FeedbackModal';
import { TimeRange } from '../temperature-graph/SiteTemperatureChartWidget';
import { isFilterComplete } from '../../../../esg-explorer/components/Tabs/Explorer/ExplorerConfiguration/Filters/Filter';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import useSiteMetricTypes from '@hooks/useSiteMetricTypes';
import SkeletonLoader from './SkeletonLoader';
import dayjs from 'dayjs';
import { MetricType } from '@api/enums/MetricType';
import OutlierResults from './OutlierResults';
import { esgMetricTypeExistInSite } from './MetricSelect';

const LocalStorageOutlierConfigKey = 'LastOutlierWidgetConfig';

const parseConfig = (str: string | null): TOutlierConfig | undefined => {
  if (!str) {
    return;
  }

  const parsed: TOutlierConfig = JSON.parse(str);

  return {
    ...parsed,
    timeRange: { from: dayjs(parsed.timeRange.from), to: dayjs(parsed.timeRange.to) }
  };
};

const getDefaultConfig = (siteMetricTypes: MetricType[]): TOutlierConfig => {
  const lastConfig = parseConfig(localStorage.getItem(LocalStorageOutlierConfigKey));

  if (lastConfig?.metric && esgMetricTypeExistInSite(siteMetricTypes, lastConfig.metric)) {
    return lastConfig;
  }

  if (siteMetricTypes.includes(MetricType.Temperature)) {
    return {
      metric: EsgMetricType.TemperatureAvg,
      timeRangeType: TimeRangeType.LastSevenDays,
      timeRange: DefaultTimeRanges[TimeRangeType.LastSevenDays],
      filter: { metric: EsgMetricType.TemperatureAvg, type: EsgExplorerFilterType.GreaterThanOrEqualTo, threshold: 24 }
    };
  }

  if (siteMetricTypes.includes(MetricType.ElectricityKwhDelta)) {
    return {
      metric: EsgMetricType.ElectricityTotalKwh,
      timeRangeType: TimeRangeType.LastSevenDays,
      timeRange: DefaultTimeRanges[TimeRangeType.LastSevenDays]
    };
  }

  return {
    timeRangeType: TimeRangeType.LastSevenDays,
    timeRange: DefaultTimeRanges[TimeRangeType.LastSevenDays]
  };
}

export type TOutlierConfig = {
  metric?: EsgMetricType;
  timeRangeType: TimeRangeType;
  timeRange: TimeRange;
  filter?: EsgExplorerFilter;
}

const OutlierWidget = () => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { execute, loading } = useApi();
  const { tenant } = useTenantContext();
  const { site, buildings, siteMetadata } = useSiteContext();
  const { siteSpaceTypes, loading: loadingSpaceTypes } = useSiteSpaceTypes(site.id);
  const [config, setConfig] = useState<TOutlierConfig>();
  const [data, setData] = useState<EsgExplorerMetricsDto>();
  const { isOpen: isModalOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const { siteMetricTypes, loading: loadingSiteMetrics } = useSiteMetricTypes(site.id);

  const handleGenerate = useCallback(async (saveConfig: boolean) => {
    if (!config || !siteSpaceTypes || !config.metric) {
      return;
    }

    const fullConfig: EsgExplorerConfiguration = {
      siteId: site.id,
      buildingIds: buildings.map(x => x.id),
      spaceTypes: siteSpaceTypes.map(x => x),
      lettingStatuses: Object.values(OccupancyStatus).map(x => x),
      metricTypes: [config.metric],
      timeRange: { from: config.timeRange.from.toISOString(), to: config.timeRange.to.toISOString() },
      filters: config.filter && isFilterComplete(config.filter) ? [config.filter] : []
    };

    const data = await execute({
      query: new EsgExplorerDataGetQuery(tenant.tenantId, fullConfig, siteMetadata?.gasCalorificValue ?? FallbackGasCalorificValue)
    });

    setData(data);

    if (saveConfig) {
      // Store latest config in local storage
      localStorage.setItem(LocalStorageOutlierConfigKey, JSON.stringify(config));
    }
  }, [execute, config, siteMetadata, tenant, buildings, site, siteSpaceTypes]);

  // Set initial config to default values or the latest config from local storage
  // Generate once initially with the default values or the latest config from local storage
  useEffect(() => {
    if (!siteMetricTypes) {
      return;
    }

    if (!config) {
      setConfig(getDefaultConfig(siteMetricTypes));
    }

    if (config && !data) {
      handleGenerate(false);
    }
  }, [config, data, siteMetricTypes, handleGenerate]);

  if (loadingSiteMetrics || loadingSpaceTypes || !config) {
    return (
      <SkeletonLoader numRows={4} />
    );
  }

  return (
    <Card noPadding style={{ padding: 32 }}>
      <FlexColumn>
        <FlexRow>
          <Title text={`${t('Outliers', { ns: 'common' })} (${data?.spaces.length ?? ''})`} />

          <Tooltip
            placement={TooltipPlacement.Bottom}
            fixedPosition={{ x: 14, y: 20 }}
            content={
              <TooltipContent>
                {t('SiteOverview.Outliers.Tooltip', { ns: 'molecules' })}
              </TooltipContent>
            }
          >
            <InfoIcon
              icon={regular('circle-info')}
              onClick={() => trackAction('tooltip', 'overview_outliers', { metric: config.metric, time_span: config.timeRangeType, filter: config.filter?.type })}
            />
          </Tooltip>

          <BetaLabel />

          <FeedbackModal
            modalRef={modalRef}
            isOpen={isModalOpen}
            toggle={toggleModal}
          />

          <Button
            tertiary
            label={t('GiveFeedback', { ns: 'common' })}
            onClick={toggleModal}
            small
          />

          {data && data.spaces.length > 0 &&
            <OutlierExport
              config={config}
              spaces={data.spaces}
            />
          }
        </FlexRow>

        <OutlierConfig
          config={config}
          siteMetricTypes={siteMetricTypes}
          onChange={setConfig}
          onGenerate={() => handleGenerate(true)}
        />

        <OutlierResults
          config={config}
          loading={loading}
          data={data}
        />
      </FlexColumn>
    </Card>
  );
};

export default OutlierWidget;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  min-height: 36px;
  margin-bottom: 26px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  width: 275px;
  padding: 10px;
  font-size: 12px;
`;