import styled from 'styled-components';
import { MetricTypeValue } from './EnergyWidgets';
import { ReactNode } from 'react';
import DoughnutChart, { DoughnutChartDataset } from '@components/charts/DoughnutChart';
import { Card } from '@components/core/Card';
import { numberWithCommas } from '@utils/NumberUtils';
import MonoSpace from '@components/core/MonoSpace';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  cardTitle: string,
  data: MetricTypeValue[],
  colors: string[],
  innerLabel?: string,
  innerSubLabel?: string,
  legendValueSuffix?: string,
  tooltipHeading?: string,
  tooltipText?: ReactNode,
  hideWithZeroValues?: boolean // Hide the widget if all values are zero.
  isCurrency?: boolean,
}

const EnergyDoughnutWidget = ({ cardTitle, data, colors, innerLabel, innerSubLabel, legendValueSuffix, tooltipHeading, tooltipText, hideWithZeroValues, isCurrency }: PropTypes) => {
  const { siteCurrencyFormat } = useSiteContext();
  const { t } = useTranslation(['molecules']);

  const chartDataset: DoughnutChartDataset = {
    data: data.map(x => x.value),
    colors: colors
  };

  if (hideWithZeroValues && !data.some(x => x.value > 0)) {
    return null;
  }

  const value: number = chartDataset.data.reduce((accumulator, current) => accumulator + current, 0)

  return (
    <Card
      cardTitle={cardTitle}
      autoWidth
      modalTitle={tooltipHeading}
      modalContent={tooltipText}
      noMargin
    >
      <DoughnutChart
        dataset={chartDataset}
        innerValue={
          <>
            {isCurrency === true ? siteCurrencyFormat(value) : numberWithCommas(value)}
          </>
        }
        innerLabel={innerLabel}
        innerSubLabel={innerSubLabel}
        size="270px"
      />
      <Legend>
        {data.map((x, i) => (
          <LegendRow key={i}>
            <LegendColorSquare color={colors[i % colors.length]} />
            <LegendMetricLabel>{t(`EnergyWidgets.${x.metricType}`)}</LegendMetricLabel>
            <LegendValue><MonoSpace>{isCurrency === true ? siteCurrencyFormat(x.value) : numberWithCommas(x.value)}</MonoSpace>{legendValueSuffix}</LegendValue>
          </LegendRow>
        ))}
      </Legend>
    </Card>
  )
}

export default EnergyDoughnutWidget;

const Legend = styled.div`
  margin-top: 20px;
`;

const LegendRow = styled.div`
  display: flex;
  align-items: center;
`;

const LegendColorSquare = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 2px;
  background-color: ${p => p.color};
`;

const LegendMetricLabel = styled.div`
  font-size: 16px;
  color: ${p => p.theme.palette.text.weak};
`;

const LegendValue = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
`;