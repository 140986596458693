import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import styled from 'styled-components';
import { Title } from '@components/core/Title';
import { ClimateControlSettingsWidget } from '../climate-control-settings-widget/ClimateControlSettingsWidget';
import { useTranslation } from 'react-i18next';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { isNullOrUndefined } from '@utils/NumberUtils';
import AddException from './AddException';
import RemoveException from './RemoveException';
import { Link } from 'react-router-dom';
import { TRVControlMode } from '@api/enums/TRVControlMode';
import { Card } from '@components/core/Card';

const hasOverridenValues = (spaceClimateControl: SpaceClimateControlDto) => {
  return (
    !isNullOrUndefined(spaceClimateControl.minTemp) ||
    !isNullOrUndefined(spaceClimateControl.maxTemp) ||
    !isNullOrUndefined(spaceClimateControl.targetTemp) ||
    !isNullOrUndefined(spaceClimateControl.controlMode)
  );
};

interface IClimateControlExceptions {
  siteClimateControl: SiteClimateControlDto;
  refresh: () => void;
  onHasUnsavedChanges: (state: boolean) => void;
}

const ClimateControlExceptions = ({ siteClimateControl, refresh, onHasUnsavedChanges }: IClimateControlExceptions) => {
  const { t } = useTranslation();
  const [changeTracker, setChangeTracker] = useState<{ [key: string]: boolean }>({});
  const [exceptionAdded, setExceptionAdded] = useState(false);
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  /**
   * Reset change tracker when siteClimateControl object is refreshed
   */
  useEffect(() => {
    setChangeTracker({});
    setHasRefreshed(true);
  }, [siteClimateControl]);

  /**
   * Scroll the last added exception into view if it was just added and the refresh has already happened
   */
  useEffect(() => {
    if (hasRefreshed && exceptionAdded) {
      listRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
      setExceptionAdded(false);
    }

    if (hasRefreshed) {
      setHasRefreshed(false);
    }
  }, [hasRefreshed, exceptionAdded]);

  /**
   * Filter out exceptions (SpaceClimateControls with overridden values)
   */
  const exceptions = useMemo(() => (
    siteClimateControl.spaceClimateControls.filter(x => hasOverridenValues(x))
  ), [siteClimateControl]);

  /**
   * Track which exceptions have been modified
   */
  const handleChange = useCallback((hasChanged: boolean, spaceId: number) => {
    const copy = { ...changeTracker };
    copy[spaceId] = hasChanged;

    setChangeTracker(copy);
    onHasUnsavedChanges(Object.values(copy).some(hasChanges => hasChanges));
  }, [changeTracker, onHasUnsavedChanges]);

  /**
   * Handle a new exception being added
   */
  const handleExceptionAdded = () => {
    setExceptionAdded(true);
    refresh();
  }

  return (
    <Card>
      <Title text={t('Exceptions', { ns: 'molecules' })} />

      <div ref={listRef}>
        {exceptions?.map((spaceClimateControl) => (
          <ControlWrapper key={spaceClimateControl.id}>
            <FlexRow style={{ paddingBottom: '60px' }}>
              <SpaceLabel to={`./../building/${spaceClimateControl.buildingId}/floor/${spaceClimateControl.floorId}/space/${spaceClimateControl.spaceId}`}>
                {spaceClimateControl.spaceName}
              </SpaceLabel>

              <RemoveException
                spaceClimateControl={spaceClimateControl}
                refresh={refresh}
              />
            </FlexRow>

            <ClimateControlSettingsWidget
              siteClimateControl={siteClimateControl}
              spaceClimateControl={spaceClimateControl}
              refresh={refresh}
              onHasUnsavedChanges={state => handleChange(state, spaceClimateControl.spaceId)}
              isToggleDisabled={spaceClimateControl.controlMode === TRVControlMode.TRV && !spaceClimateControl.spaceHasTemperatureMonitoringDevices}
              showMissingDeviceWarning={spaceClimateControl.controlMode === TRVControlMode.External && !spaceClimateControl.temperatureMonitoringDeviceId}
            />
          </ControlWrapper>
        ))}
      </div>

      <AddException
        siteClimateControl={siteClimateControl}
        exceptions={exceptions}
        onExceptionAdded={handleExceptionAdded}
      />

    </Card>
  );
};

export default ClimateControlExceptions;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const SpaceLabel = styled(Link)`
  font-size: 16px;
  line-height: 16px;
  color: ${p => p.theme.text.secondary};
  padding: 10px 0;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const ControlWrapper = styled.div`
  padding: 20px 0 30px 0;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;