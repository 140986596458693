import styled, { css } from 'styled-components';
import { MetricTypeValue } from './EnergyWidgets';
import { ReactNode, useState } from 'react';
import DoughnutChart, { DoughnutChartDataset } from '@components/charts/DoughnutChart';
import { Card } from '@components/core/Card';
import { numberWithCommas } from '@utils/NumberUtils';
import MonoSpace from '@components/core/MonoSpace';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useTranslation } from 'react-i18next';
import { MetricType } from '@api/enums/MetricType';
import { transparentize } from 'polished';
import { calculateM3ToKwh } from '@utils/EnergyUtils';

type GasEnergyDoughnutWidgetProps = {
  cardTitle: string,
  data: MetricTypeValue[],
  colors: string[],
  innerLabel?: string[],
  legendValueSuffix?: string[],
  tooltipHeading?: string,
  tooltipText?: ReactNode,
  hideWithZeroValues?: boolean // Hide the widget if all values are zero.
  isCurrency?: boolean
}

const GasEnergyDoughnutWidget = ({ cardTitle, data, colors, innerLabel, legendValueSuffix, tooltipHeading, tooltipText, hideWithZeroValues, isCurrency }: GasEnergyDoughnutWidgetProps) => {
  const { siteCurrencyFormat, siteMetadata } = useSiteContext();
  const { t } = useTranslation(['molecules']);
  const [index, setIndex] = useState<number>(0)
  const [chartDataset, setChartDataset] = useState<DoughnutChartDataset>({
    data: data.map((data: MetricTypeValue) => index === 0 ? data.value : calculateM3ToKwh(38, data.value)),
    colors: colors
  });

  if (hideWithZeroValues && !data.some(x => x.value > 0)) {
    return null;
  }

  const value: number = data.map((data: MetricTypeValue) => index === 0 ? data.value : calculateM3ToKwh(38, data.value))
    .reduce((accumulator, current) => accumulator + current, 0);

  const toggleEnergyUnit = (): void => {
    if (index === 0) {
      setChartDataset({
        data: data.map((data: MetricTypeValue) => calculateM3ToKwh(siteMetadata?.gasCalorificValue ? siteMetadata?.gasCalorificValue : 0, data.value)),
        colors: colors
      })
      setIndex(1)

    } else {
      setChartDataset({
        data: data.map((data: MetricTypeValue) => data.value),
        colors: colors
      })
      setIndex(0)
    }
  }

  return (
    <Card
      cardTitle={cardTitle}
      autoWidth
      modalTitle={tooltipHeading}
      modalContent={tooltipText}
      noMargin
    >
      <DoughnutChart
        dataset={chartDataset}
        innerValue={
          <>
            {isCurrency === true ? siteCurrencyFormat(value) : numberWithCommas(value)}
          </>
        }
        innerLabel={innerLabel ? innerLabel[index] : ''}
        innerSubLabel={
          data.length > 0 && legendValueSuffix && legendValueSuffix?.length > 1 &&
          <FlexRow>
            <ToggleWrapper>
              <ToggleButton
                selected={index === 1}
                onClick={toggleEnergyUnit}>
                {legendValueSuffix[1]}
              </ToggleButton>
              <ToggleButton
                selected={index === 0}
                onClick={toggleEnergyUnit}>
                {legendValueSuffix[0]}
              </ToggleButton>
            </ToggleWrapper>
          </FlexRow>
        }
        size="270px"
      />
      <Legend>
        {data.map((data: MetricTypeValue, i: number) => (
          <LegendRow key={i}>
            <LegendColorSquare color={colors[i % colors.length]} />
            <LegendMetricLabel>{t(`EnergyWidgets.${MetricType.GasVolumeDelta}`)}</LegendMetricLabel>
            <LegendValue>
              <MonoSpace>{isCurrency === true ? siteCurrencyFormat(data.value) : numberWithCommas(data.value)}</MonoSpace>
              {legendValueSuffix ? legendValueSuffix[index] : ''}
            </LegendValue>
          </LegendRow>
        ))}
      </Legend>
    </Card>
  )
}

export default GasEnergyDoughnutWidget;

const Legend = styled.div`
  margin-top: 20px;
`;

const LegendRow = styled.div`
  display: flex;
  align-items: center;
`;

const LegendColorSquare = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 2px;
  background-color: ${p => p.color};
`;

const LegendMetricLabel = styled.div`
  font-size: 16px;
  color: ${p => p.theme.palette.text.weak};
`;

const LegendValue = styled.div`
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: end;
`;

const ToggleWrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 1px 4px ${p => p.theme.palette.shadows.medium};
  display: flex;
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  padding: 1px 8px;
  user-select: none;
  cursor: pointer;
  
  text-align: center; 
  width: 60px;
  
  font-size: 14px;
  font-weight: 400;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  color: ${p => p.theme.palette.text.weak};

  ${({ selected }) => selected && css`
    background-color: ${p => transparentize(0.1, p.theme.palette.primary)};
    color: ${p => p.theme.palette.text.onPrimary};
  `}
`;
