import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { useSiteContext } from '@pages/site/SiteProvider';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { BackButton } from '@components/core/BackButton';
import { transparentize } from 'polished';
import { Button } from '@components/core/Button';
import { useApi } from '@hooks/useApi';
import { validateEmail } from '@utils/StringUtils';
import AlertEmailSettings from './AlertEmailSettings';
import SiteClimateControlUpdateEmailsCommand from '@api/queries/climate-control/Site/SiteClimateControlUpdateEmailsCommand';
import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { Card } from '@components/core/Card';

const emailsModified = (updated: string[], original?: string[]) => {
  if (original?.length !== updated.length) {
    return true;
  }

  return original.some(x => !updated.includes(x));
}

const ClimateControl_AlertSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();
  const [emails, setEmails] = useState<string[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [emailsAreInvalid, setEmailsAreInvalid] = useState(false);

  const resetEmails = useCallback(() => {
    setEmails(siteClimateControl?.alertEmails ?? []);
  }, [siteClimateControl]);

  useEffect(() => {
    resetEmails()
  }, [resetEmails]);

  useEffect(() => {
    setHasUnsavedChanges(emailsModified(emails, siteClimateControl?.alertEmails));
    setEmailsAreInvalid(emails.some(x => !validateEmail(x)));
  }, [siteClimateControl, emails]);

  const handleSave = useCallback(async () => {
    if (!siteClimateControl) {
      return
    }

    await execute({
      query: new SiteClimateControlUpdateEmailsCommand(siteClimateControl.id, emails),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refreshSiteClimateControl();
  }, [siteClimateControl, emails, execute, refreshSiteClimateControl, t]);

  return (
    <>
      <RouterPrompt when={hasUnsavedChanges} />

      <PaddedContainer centered>
        <Container>
          <BackButton
            label={t('BackToClimateControlSettings', { ns: 'navigation' })}
            url='./..'
          />

          <Card cardTitle={t('ClimateControl.ClimateControlEmailAlerts', { ns: 'molecules' })}>
            <Text>
              {t('ClimateControl.AlertEmailInstruction', { ns: 'molecules' })}
            </Text>

            <AlertEmailSettings
              emails={emails}
              onChange={setEmails}
            />

            <FooterRow>
              <Button tertiary
                color={theme.palette.red}
                label={t('Discard', { ns: 'common' })}
                onClick={resetEmails}
                disabled={!hasUnsavedChanges}
              />
              <Button
                label={t('Save', { ns: 'common' })}
                onClick={handleSave}
                disabled={emailsAreInvalid || !hasUnsavedChanges}
              />
            </FooterRow>
          </Card>
        </Container>
      </PaddedContainer>
    </>
  );
}

export default ClimateControl_AlertSettings;

const Container = styled.div`
  width: 100%;
  max-width: 480px;
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  margin-top: 20px;
  background-color: ${p => transparentize(0.4, p.theme.palette.backgrounds.surface)};
`;

const Text = styled.div`
  font-size: 14px;
`;