import { ClimateControlStatus } from '@api/enums/ClimateControlStatus';
import { FeatureFlag } from '@api/enums/FeatureFlag';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Title } from '@components/core/Title';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClimateControlV2 from '@pages/site/temperature-control/v2/ClimateControlV2';
import BuildingList from './components/buildings/BuildingList';
import ClimateControlPreview from './ClimateControlPreview';
import ClimateControlSettings from './components/climate-control-settings/ClimateControlSettings';

const ClimateControl = () => {
  const { t } = useTranslation();
  const { hasFeatureFlag } = useTenantContext();
  const { siteClimateControl } = useSiteContext();
  const climateControlV2Enabled = hasFeatureFlag(FeatureFlag.ClimateControlV2);

  if (!siteClimateControl || siteClimateControl.status === ClimateControlStatus.Disabled) {
    return <ClimateControlPreview />;
  }

  if (climateControlV2Enabled) {
    return <ClimateControlV2 />
  }

  return (
    <PaddedContainer>
      <Title
        size='lg'
        text={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
        style={{ marginBottom: '20px' }}
      />

      <FlexRow>
        <ClimateControlSettings />
        <BuildingList />
      </FlexRow>
    </PaddedContainer>
  );
};

export default ClimateControl;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;