import { LineBarChartType } from '@components/charts/Chart.types';
import { AbbreviatedToFullMonthName } from '@utils/DateUtils';
import { numberWithCommas } from '@utils/NumberUtils';
import { ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import { YearlyChartDataset } from './useCumulativeChart';

export const constructChartOptions = (theme: DefaultTheme): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500
    },
    scales: {
      x: {
        grid: {
          borderColor: theme.action.divider,
          borderWidth: 1,
          tickLength: 5,
          drawOnChartArea: false
        },
        ticks: {
          display: true,
          maxRotation: 0,
          padding: 8,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          },
        },
      },
      y: {
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.5, theme.action.divider),
        },
        ticks: {
          padding: 10,
          maxTicksLimit: 5,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          },
          callback: (value: string | number) => {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(Number(value));
          }
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        fill: true,
        tension: 0.3,
      },
      point: {
        radius: 0,
        hoverRadius: 0
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        usePointStyle: true,
        caretPadding: 12,
        caretSize: 7,
        backgroundColor: theme.palette.backgrounds.surface,
        cornerRadius: 5,
        padding: 14,
        borderColor: theme.palette.borders.medium,
        borderWidth: 1,
        titleMarginBottom: 9,
        titleColor: theme.palette.text.fair,
        titleFont: {
          family: theme.fontFamily,
          weight: '600',
          size: 14,
        },
        bodyColor: theme.palette.text.fair,
        bodySpacing: 7,
        bodyFont: {
          family: theme.fontFamily,
          weight: '400',
          size: 14,
        },
        callbacks: {
          title: (items: TooltipItem<'bar' | 'line'>[]) => {
            const label = items[0]?.label;
            return `${AbbreviatedToFullMonthName(label)}`;
          },
          label: (item: TooltipItem<'bar' | 'line'>) => {
            if (isNaN(item.raw as number)) {
              return '  --'
            }

            const dataset = (item.dataset as unknown as YearlyChartDataset);
            const unit = dataset.dataUnit;

            return ` ${dataset.label}: ${numberWithCommas(item.raw as number, 2)}${unit}`;
          },
          labelPointStyle: (item: TooltipItem<'bar' | 'line'>) => {
            return {
              pointStyle: item.dataset.type === LineBarChartType.Line ? 'line' : 'rectRounded',
              rotation: 0
            };
          },
        },
      },
      legend: {
        display: false
      }
    }
  };
}