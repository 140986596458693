import { UtilityConsumptionDto } from '@api/models/UtilityConsumptionDto';
import { Locales } from '@api/enums/Locales';
import { MetricType } from '@api/enums/MetricType';
import { LocalisationType } from '@i18n//localisation';
import { calculateM3ToKwh } from '@utils/EnergyUtils';
import { getDaysInMonth } from 'date-fns';
import { round } from 'lodash';

export type ConsumptionWidgetMetricConfig = {
  unit: string;
  modifier: (x: number | null) => number;
  conversion?: {
    unit: string;
    modifier: (x: number) => number;
  };
  isGasWidget?: boolean;
};

const applyToLocale = (number: number | null, toLocale?: (value: number) => number): number | null => {
  if (number && toLocale) {
    return toLocale(number)
  }
  return null
}

const applyFromLocale = (number: number, fromLocale?: (value: number) => number): number => {
  if (fromLocale) {
    return fromLocale(number)
  }
  return number
}

export const getConsumptionWidgetConfig = (metricType: MetricType, localisation: LocalisationType, gasCalorificValue?: number): ConsumptionWidgetMetricConfig => {
  switch (metricType) {
    case MetricType.CoolingKwhDelta:
    case MetricType.ElectricityKwhDelta:
    case MetricType.HeatingKwhDelta:
      return {
        unit: ' kWh',
        modifier: (x) => x ?? NaN,
        conversion: {
          unit: ' MWh',
          modifier: (x) => x === null ? NaN : x / 1000,
        }
      };

    case MetricType.WaterVolumeDelta:
      if (localisation.locale === Locales.enUS) {
        const toLocale = localisation.metrics[MetricType.WaterVolumeDelta].toLocale;
        return {
          unit: ' gal',
          modifier: (x) => applyToLocale(x, toLocale) ?? NaN
        };
      } else {
        return {
          unit: ' m³',
          modifier: (x) => x ?? NaN
        };
      }

    case MetricType.GasVolumeDelta:
      if (localisation.locale === Locales.enUS) {
        const toLocale = localisation.metrics[MetricType.GasVolumeDelta].toLocale;
        const fromLocale = localisation.metrics[MetricType.GasVolumeDelta].fromLocale;
        return {
          unit: ' ft³',
          modifier: (x) => applyToLocale(x, toLocale) ?? NaN,
          isGasWidget: true,
          conversion: {
            unit: ' kWh',
            modifier: (x) => gasCalorificValue ? calculateM3ToKwh(gasCalorificValue, applyFromLocale(x, fromLocale)) : applyFromLocale(x, fromLocale)
          }
        }
      } else {
        return {
          unit: ' m³',
          modifier: (x) => x ?? NaN,
          isGasWidget: true,
          conversion: {
            unit: ' kWh',
            modifier: (x) => gasCalorificValue ? calculateM3ToKwh(gasCalorificValue, x) : x
          }
        };
      }

    default:
      return {
        unit: '',
        modifier: (x) => x ?? NaN
      };
  }
};

// Create a data point by applying a modifer and optional conversion function to the value
export const createDataPoint = (value: number | null, config: ConsumptionWidgetMetricConfig, applyConversion: boolean) => {
  let dataPoint = config.modifier(value);

  if (applyConversion) {
    dataPoint = config.conversion ? config.conversion?.modifier(dataPoint) : dataPoint;
  }

  return round(dataPoint, 2);
};

export const createDataset = (values: (number | null)[], config: ConsumptionWidgetMetricConfig, applyConversion: boolean) => {
  return values.map(x => createDataPoint(x, config, applyConversion));
};

// Calculate the target value for the year-to-date
export const getTargetYTD = (data: UtilityConsumptionDto, config: ConsumptionWidgetMetricConfig, applyConversion: boolean) => {
  const today = new Date();
  const month = today.getMonth();

  let targetYTD = 0;
  for (let i = 0; i < month; i++) {
    targetYTD += data.monthlyTargets[i] ?? 0;
  }

  const day = today.getDate();
  const numDays = getDaysInMonth(today);
  const currentMonthTarget = data.monthlyTargets[month] ?? 0;

  const currentMonthTargetYTD = (currentMonthTarget / numDays) * day;
  targetYTD += currentMonthTargetYTD;

  targetYTD = config.modifier(targetYTD);

  if (applyConversion) {
    targetYTD = config.conversion ? config.conversion?.modifier(targetYTD) : targetYTD;
  }

  return round(targetYTD, 2);
};

// Sorting array to sort utility tabs
const utilitySortingArray = [MetricType.ElectricityKwhDelta, MetricType.HeatingKwhDelta, MetricType.CoolingKwhDelta, MetricType.GasVolumeDelta, MetricType.WaterVolumeDelta];

// Sort utilities by metric type
export const sortUtilities = (data: UtilityConsumptionDto[]) => {
  return data.sort((a, b) => utilitySortingArray.indexOf(a.metricType) - utilitySortingArray.indexOf(b.metricType));
};