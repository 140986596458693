import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Tooltip } from '@components/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import CustomNumberInput from './CustomNumberInput';
import { ClimateControlFallbackConfig } from '@api/models/SiteClimateControlDto';
import { CLIMATE_CONTROL_MAX_TEMPERATURE, CLIMATE_CONTROL_MIN_TEMPERATURE } from '../climate-control-settings-widget/ClimateControlSettingsWidget';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';

const OFFLINE_SENSOR_MIN_THRESHOLD = 1;
const OFFLINE_SENSOR_MAX_THRESHOLD = 72;

type OfflineSensorConfigurationProps = {
  config: ClimateControlFallbackConfig;
  onChange: (config: ClimateControlFallbackConfig) => void;
}

const OfflineSensorConfiguration = ({ config, onChange }: OfflineSensorConfigurationProps) => {
  const { t } = useTranslation();
  const { fromLocale, toLocale, getUnit } = useLocalisation();

  const hoursLabel = config.offlineSensorThreshold === 1
    ? t('ClimateControl.Hour', { ns: 'molecules' })
    : t('ClimateControl.Hours', { ns: 'molecules' });

  return (
    <>
      <FlexRow>
        <StyledLabel>
          {t('ClimateControl.ExternalSensorIsOffline', { ns: 'molecules' })}
        </StyledLabel>

        <Tooltip
          content={
            <TooltipContent>
              {t('ClimateControl.ExternalSensorIsOfflineTooltip', { ns: 'molecules' })}
            </TooltipContent>
          }
        >
          <InfoIcon
            icon={regular('circle-info')}
          />
        </Tooltip>
      </FlexRow>

      <TableWrapper>
        <TableRow>
          <Col>
            {t('ClimateControl.After', { ns: 'molecules' })}
            <CustomNumberInput
              min={OFFLINE_SENSOR_MIN_THRESHOLD}
              max={OFFLINE_SENSOR_MAX_THRESHOLD}
              value={config.offlineSensorThreshold}
              onChange={value => onChange({ ...config, offlineSensorThreshold: value })}
            />
            <Unit>{hoursLabel}</Unit> {t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })}
            <CustomNumberInput
              min={toLocale(MetricType.Temperature, CLIMATE_CONTROL_MIN_TEMPERATURE)}
              max={toLocale(MetricType.Temperature, CLIMATE_CONTROL_MAX_TEMPERATURE)}
              value={toLocale(MetricType.Temperature, config.offlineSensorTemp, { round: 0 })}
              onChange={value => onChange({ ...config, offlineSensorTemp: fromLocale(MetricType.Temperature, value) })}
            />
            <Unit style={{ marginLeft: '-22px' }}>{getUnit(MetricType.Temperature)}</Unit>
          </Col>
        </TableRow>
      </TableWrapper>
    </>
  );
};

export default OfflineSensorConfiguration;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 5px 0;
`;

const StyledLabel = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  margin-right: 5px;
`;

const TooltipContent = styled.div`
  width: 280px;
  padding: 10px;
  font-size: 12px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const TableWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${p => p.theme.action.divider};
  box-shadow: 0 1px 3px 0px ${p => p.theme.palette.shadows.medium};

  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const TableRow = styled.div`
  padding: 7px 15px;
  display: flex;
  color: ${p => p.theme.palette.text.fair};
  user-select: none;
  
  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const Col = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;

  ${p => p.isDisabled && css`
    color: ${p => p.theme.text.secondary};
  `}
`;

const Unit = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-right: 7px;
  margin-left: -20px;
`;