import UtilitiesSummary from '@pages/site/overview/v2/components/utility-summaries/UtilitiesSummary';
import SiteTemperatureChartWidget from '@pages/site/overview/v2/components/temperature-graph/SiteTemperatureChartWidget';
import { Title } from '@components/core/Title';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SitePagePerformance = () => {
  const { t } = useTranslation();

  return (
    <PaddedContainer>
      <Title
        text={t('SitePerformance', { ns: 'common' })}
        subtitle={{ text: t('SitePeformanceDescription', { ns: 'common' }), style: { paddingTop: 12 } }}
        size='lg'
        wrapperStyle={{ paddingBottom: '32px' }}
      />

      <FlexColumn>
        <SiteTemperatureChartWidget />
        <UtilitiesSummary />
      </FlexColumn>
    </PaddedContainer>
  );
};

export default SitePagePerformance;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;