import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { useCallback, useState } from 'react';
import ClimateControlExceptions from './exceptions/ClimateControlExceptions';
import { useSiteContext } from '@pages/site/SiteProvider';
import styled from 'styled-components';
import FallbackSettings from './fallback-settings/FallbackSettings';
import SiteClimateControl from './site/SiteClimateControl';

type ChangeTracker = {
  site: boolean;
  fallback: boolean;
  exceptions: boolean;
}

const ClimateControlSettings = () => {
  const [hasChanges, setHasChanges] = useState<ChangeTracker>({ site: false, fallback: false, exceptions: false });
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();

  const handleFallbackSettingsChange = useCallback((state: boolean) => {
    setHasChanges(prevState => ({ ...prevState, fallback: state }));
  }, []);

  if (!siteClimateControl) {
    return null;
  }

  return (
    <>
      <RouterPrompt when={hasChanges.site || hasChanges.fallback || hasChanges.exceptions} />

      <FlexColumn>
        <SiteClimateControl
          siteClimateControl={siteClimateControl}
          refresh={refreshSiteClimateControl}
          onHasUnsavedChanges={state => setHasChanges(prevState => ({ ...prevState, site: state }))}
        />

        <FallbackSettings
          siteClimateControl={siteClimateControl}
          refresh={refreshSiteClimateControl}
          onChange={handleFallbackSettingsChange}
        />

        <ClimateControlExceptions
          siteClimateControl={siteClimateControl}
          refresh={refreshSiteClimateControl}
          onHasUnsavedChanges={state => setHasChanges(prevState => ({ ...prevState, exceptions: state }))}
        />
      </FlexColumn>
    </>
  );
};

export default ClimateControlSettings;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (min-width: 1050px) {
    width: calc(50% - 15px);
  }
`;