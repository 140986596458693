import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ICascaderMultiSelect } from './CascaderMultiSelect.types';
import CascaderModal from './modal/CascaderModal';
import { Loading } from '@components/core/Loading';
import useCascaderMultiSelect from '@hooks/useCascaderMultiSelect';
import { AddExceptionButton } from '@pages/site/temperature-control/v1/components/climate-control-settings/exceptions/AddException';
import { SelectField } from '@components/select-field/SelectField';

export const CascaderMultiSelect = <TValue,>(props: ICascaderMultiSelect<TValue>) => {
  const { hideChildGroupLabel, confirmButton, cancelButton, loading, fieldStyles, placeholder, maxFieldHeight = 75, showAddButton } = props;
  const {
    isModalOpen,
    modalRef,
    hasMinimumHeight,
    transientNodeGroup,
    selectedLabels,
    toggleModal,
    selectFieldRef,
    handleSelect,
    handleApply
  } = useCascaderMultiSelect(props);

  return (
    <Container>
      <CascaderModal
        modalRef={modalRef}
        open={isModalOpen}
        group={transientNodeGroup}
        onChange={handleSelect}
        onApply={handleApply}
        onCancel={toggleModal}
        hideChildGroupLabel={hideChildGroupLabel}
        confirmButton={confirmButton}
        cancelButton={cancelButton}
      />

      {showAddButton &&
        <AddExceptionButton
          icon={solid('plus')}
          onClick={() => !loading && toggleModal()}
        />}

      {!showAddButton &&
        <div ref={selectFieldRef}>
          <SelectField
            onClick={() => !loading && toggleModal()}
            style={fieldStyles}
            maxFieldHeight={maxFieldHeight}
            hasOverflow={hasMinimumHeight}
          >
            <EditIcon icon={regular('edit')} />

            {selectedLabels.length === 0 &&
              <Placeholder>
                {placeholder}
              </Placeholder>
            }

            {loading &&
              <Loading
                size="15px"
                color="grey"
                style={{ margin: '6px 0 0 auto' }}
              />
            }

            {selectedLabels.map((label, i) => (
              <SelectedLabel key={i}>
                {label}
              </SelectedLabel>
            ))}
          </SelectField>
        </div>}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const EditIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 7px;
  right: 8px;

  width: 12px;
  height: 12px;
  padding: 6px;
  color: ${p => p.theme.action.active};
  border-radius: 50%;
  
  transition: all 150ms ease;
  
  ${SelectField}:hover & {
    color: ${p => p.theme.primary.main};
  }
`;

const Placeholder = styled.div`
  font-size: 14px;
  margin-right: 8px;
  margin-top: 1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedLabel = styled.div`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1px 7px;

  font-size: 14px;
  font-weight: 500;

  border-radius: 5px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};
  transition: all 150ms ease;
`;