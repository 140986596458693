import { FlexColumn } from '@components/core/FlexColumn';
import FlexRow from '@components/core/FlexRow';
import { Title } from '@components/core/Title';
import { Card } from '@components/core/Card';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClimateControlSlider from '@pages/site/temperature-control/v2/components/ClimateControlSlider';
import { climateControlSliderGradientSteps } from '@pages/site/temperature-control/v2/ClimateControlUtils';
import { TemperatureRangeForm } from '../types/TemperatureRangeForm';
import RestRecommended from './ResetRecommended';

const SiteTemperature = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<TemperatureRangeForm>();
  const minTemp = watch('temperatureRangeSettings.minTemp');
  const maxTemp = watch('temperatureRangeSettings.maxTemp');
  const targetTemp = watch('temperatureRangeSettings.targetTemp');

  return (
    <Card>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <StyledFlexColumn>
          <StyledFlexRow>
            <Title
              text={t('ClimateControl.SiteTemperature', { ns: 'molecules' })}
            />
          </StyledFlexRow>
          <StyledLabel>
            {t('ClimateControl.SiteTemperatureDesc', { ns: 'molecules' })}
          </StyledLabel>
          <ClimateControlSlider
            minTemp={minTemp}
            maxTemp={maxTemp}
            targetTemp={targetTemp}
            minTempFormId={'temperatureRangeSettings.minTemp'}
            maxTempFormId={'temperatureRangeSettings.maxTemp'}
            targetTempFormId={'temperatureRangeSettings.targetTemp'}
            climateControlSliderGradientSteps={climateControlSliderGradientSteps}
          />
        </StyledFlexColumn>
        <RestRecommended />
      </FlexRow>
    </Card>
  )
}

export default SiteTemperature

const StyledFlexColumn = styled(FlexColumn)`
  position: relative;
  gap: 30px;
  width: 400px;
  max-width: 400px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledFlexRow = styled(FlexRow) <{ disabled?: boolean }>`
  align-items: center;
  gap: 10px;
  opacity: ${p => p.disabled ? 0.5 : 1};
  transition: opacity .1s;
`;

const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.text.secondary};
`;