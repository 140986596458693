import CarbonGenerationMixDto from '@api/models/CarbonGenerationMixDto';
import CarbonGenerationMixGetQuery from '@api/queries/metrics/CarbonGenerationMixGetQuery';
import DoughnutChart, { DoughnutChartDataset } from '@components/charts/DoughnutChart';
import { Loading } from '@components/core/Loading';
import { Card } from '@components/core/Card';
import { useApiState } from '@hooks/useApiState';
import { useSiteContext } from '@pages/site/SiteProvider';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Carbon generation mix fuels
 */
enum GenerationMixFuel {
  biomass = 'biomass',
  coal = 'coal',
  imports = 'imports',
  gas = 'gas',
  nuclear = 'nuclear',
  other = 'other',
  hydro = 'hydro',
  solar = 'solar',
  wind = 'wind',
  peat = 'peat',
  otherRenewables = 'otherRenewables',
  waste = 'waste'
}

/**
 * Map generation mix fuel type to a chart color
 */
const GenerationMixColors = {
  [GenerationMixFuel.biomass]: '#0d8100',
  [GenerationMixFuel.coal]: '#fe6148',
  [GenerationMixFuel.imports]: '#6b7e88',
  [GenerationMixFuel.gas]: '#fe9a3e',
  [GenerationMixFuel.nuclear]: '#68b421',
  [GenerationMixFuel.other]: '#aabfca',
  [GenerationMixFuel.hydro]: '#005bbd',
  [GenerationMixFuel.solar]: '#fed234',
  [GenerationMixFuel.wind]: '#00A2FF',
  [GenerationMixFuel.peat]: '#97591f',
  [GenerationMixFuel.otherRenewables]: '#00c8cf',
  [GenerationMixFuel.waste]: '#f1c500'
};

const GenerationMixWidget = () => {
  const { site } = useSiteContext();
  const { t } = useTranslation(['molecules']);
  const { data, loading } = useApiState({
    query: new CarbonGenerationMixGetQuery(site.id)
  }, [site]);

  const getCarbonMixDataset = (data: CarbonGenerationMixDto): DoughnutChartDataset => {
    const fuels = data.generationMix.filter(x => x.percentage > 0).sort((a, b) => b.percentage - a.percentage);
    const values = fuels.map(x => x.percentage);
    const labels = fuels.map(x => `${x.percentage}% ${t(`EnergyWidgets.${x.fuel}`, { ns: 'molecules' })}`);
    const colors = fuels.map(x => GenerationMixColors[x.fuel as GenerationMixFuel]);


    return {
      data: values,
      colors: colors,
      labels: labels
    };
  };

  return (
    <Card
      cardTitle={t('EnergyWidgets.CarbonGeneration', { ns: 'molecules' })}
      autoWidth
      modalTitle={t('EnergyWidgets.CarbonGenerationWidget', { ns: 'molecules' })}
      modalContent={
        <Trans i18nKey='molecules:EnergyWidgets.CO2Text'>
          <p></p>
        </Trans>
      }
      noMargin
    >

      {loading &&
        <Loading message={t('EnergyWidgets.LoadingGenerationMix', { ns: 'molecules' })} />
      }

      {!loading && data &&
        <DoughnutChart
          dataset={getCarbonMixDataset(data)}
          size="190px"
          cutout="88%"
          arcBorderWidth={2}
          innerValue={`${data.intensityForecast}`}
          innerLabel="gCO&#8322;/kWh"
          legend={{
            maxHeight: 196 // 7 legend items high (7x28px)
          }}
        />
      }
    </Card>
  )
}


export default React.memo(GenerationMixWidget);