import placeholderImage from '@assets/images/buildingImagePlaceholder.png';
import { ClimateControlBuildingSummaryDto } from '@api/models/ClimateControlBuildingSummaryDto';
import { useFileFromBlobStorage } from '@hooks/useFileFromBlobStorage';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const getSpaceLabel = (count: number, t: TFunction) => {
  return count === 1 ? t('Space', { ns: 'assets' }) : t('Spaces', { ns: 'assets' });
}

type BuildingListItemProps = {
  building: ClimateControlBuildingSummaryDto
}

const BuildingListItem = ({ building }: BuildingListItemProps) => {
  const { t } = useTranslation();
  const { blobUri } = useFileFromBlobStorage(building.buildingImageId);

  return (
    <ListItem to={`heatmap/${building.buildingId}`}>
      <Image src={blobUri ?? placeholderImage} />

      <div>
        <Name>
          {building.buildingName}
        </Name>

        {building.aboveMaxCount > 0 &&
          <Issue>
            {t('ClimateControl.AboveMaxTemp', { ns: 'molecules' })}: {building.aboveMaxCount} {getSpaceLabel(building.aboveMaxCount, t)}
          </Issue>
        }

        {building.belowMinCount > 0 &&
          <Issue>
            {t('ClimateControl.BelowMinTemp', { ns: 'molecules' })}: {building.belowMinCount} {getSpaceLabel(building.belowMinCount, t)}
          </Issue>
        }

        {building.deviceIssueCount > 0 &&
          <Issue>
            {t('ClimateControl.DeviceIssues.Label', { ns: 'molecules' })}: {building.deviceIssueCount}
          </Issue>
        }
      </div>
    </ListItem>
  );
};

export default BuildingListItem;

const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 15px 15px;
  border-radius: 8px;

  &:hover {
    background-color: ${p => p.theme.primary.hover};
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 4px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ${ListItem}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;

const Issue = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
`;