import { SpaceType } from './SpaceType';

import { ReactComponent as BathroomSvg } from '@assets/icons/spaceTypes/bathroom.svg';
import { ReactComponent as BoardroomSvg } from '@assets/icons/spaceTypes/boardroom.svg';
import { ReactComponent as BookSvg } from '@assets/icons/spaceTypes/book.svg';
import { ReactComponent as CinemaSvg } from '@assets/icons/spaceTypes/cinema.svg';
import { ReactComponent as DefaultSvg } from '@assets/icons/spaceTypes/default.svg';
import { ReactComponent as DuplexSvg } from '@assets/icons/spaceTypes/duplex.svg';
import { ReactComponent as CupboardSvg } from '@assets/icons/spaceTypes/cupboard.svg';
import { ReactComponent as DiningSvg } from '@assets/icons/spaceTypes/dining.svg';
import { ReactComponent as FourBedSvg } from '@assets/icons/spaceTypes/four-bed.svg';
import { ReactComponent as KitchenSvg } from '@assets/icons/spaceTypes/kitchen.svg';
import { ReactComponent as OfficeSvg } from '@assets/icons/spaceTypes/office.svg';
import { ReactComponent as OneBedSvg } from '@assets/icons/spaceTypes/one-bed.svg';
import { ReactComponent as PenthouseSvg } from '@assets/icons/spaceTypes/penthouse.svg';
import { ReactComponent as ReceptionSvg } from '@assets/icons/spaceTypes/reception.svg';
import { ReactComponent as SharerSvg } from '@assets/icons/spaceTypes/sharer.svg';
import { ReactComponent as SharerMultipleSvg } from '@assets/icons/spaceTypes/sharerMultiple.svg';
import { ReactComponent as SpinningBikeSvg } from '@assets/icons/spaceTypes/spinningbike.svg';
import { ReactComponent as StudioSvg } from '@assets/icons/spaceTypes/studio.svg';
import { ReactComponent as ThreeBedSvg } from '@assets/icons/spaceTypes/three-bed.svg';
import { ReactComponent as TwoBedSvg } from '@assets/icons/spaceTypes/two-bed.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCouch } from '@fortawesome/pro-solid-svg-icons';
import { faElevator, faToilet } from '@fortawesome/pro-light-svg-icons';

/**
 * Get icon
 */
export const SpaceType_Icon = (spaceType: SpaceType): JSX.Element | null => {
  return spaceTypeToIcon[spaceType] ?? null;
};

export const SpaceType_Icon_Fill = (spaceType: SpaceType): boolean => {
  switch (spaceType) {
    case SpaceType.PrivateDining:
    case SpaceType.Reception:
    case SpaceType.Cinema:
    case SpaceType.Cupboard:
    case SpaceType.Kitchen:
    case SpaceType.Other:
    case SpaceType.Boardroom:
    case SpaceType.MeetingRoom:
    case SpaceType.OpenPlan:
    case SpaceType.PrivateOffice:
    case SpaceType.Studio:
    case SpaceType.Bedroom:
      return true;
    default:
      return false;
  }
};


/**
 * Map SpaceType to an icon
 */
const spaceTypeToIcon: { [key in SpaceType]: JSX.Element | null } = Object.freeze({
  /** Common */
  [SpaceType.PrivateDining]: <DiningSvg />,
  [SpaceType.Reception]: < ReceptionSvg />,
  [SpaceType.Gym]: <SpinningBikeSvg />,
  [SpaceType.Cinema]: <CinemaSvg />,
  [SpaceType.Cupboard]: <CupboardSvg />,
  [SpaceType.Kitchen]: <KitchenSvg />,
  [SpaceType.Bathroom]: <BathroomSvg />,
  [SpaceType.Toilet]: <FontAwesomeIcon icon={faToilet} />,
  [SpaceType.Elevator]: <FontAwesomeIcon icon={faElevator} />,
  [SpaceType.Library]: <BookSvg />,
  [SpaceType.Lounge]: <FontAwesomeIcon icon={faCouch} />,
  [SpaceType.Other]: <DefaultSvg />,

  /** Office */
  [SpaceType.Boardroom]: <BoardroomSvg />,
  [SpaceType.MeetingRoom]: <BoardroomSvg />,
  [SpaceType.OpenPlan]: <DefaultSvg />,
  [SpaceType.PrivateOffice]: <OfficeSvg />,

  /** Apartment */
  [SpaceType.Studio]: <StudioSvg />,
  [SpaceType.OneBed]: <OneBedSvg />,
  [SpaceType.TwoBed]: <TwoBedSvg />,
  [SpaceType.ThreeBed]: <ThreeBedSvg />,
  [SpaceType.FourBed]: <FourBedSvg />,
  [SpaceType.DuplexTwoBed]: <DuplexSvg />,
  [SpaceType.DuplexThreeBed]: <DuplexSvg />,
  [SpaceType.DuplexFourBed]: <DuplexSvg />,
  [SpaceType.SharerTwoBed]: <SharerSvg />,
  [SpaceType.SharerThreeBed]: <SharerMultipleSvg />,
  [SpaceType.SharerFourBed]: <SharerMultipleSvg />,
  [SpaceType.SharerFiveBed]: <SharerMultipleSvg />,
  [SpaceType.SharerSixBed]: <SharerMultipleSvg />,
  [SpaceType.SharerSevenBed]: <SharerMultipleSvg />,
  [SpaceType.SharerEightBed]: <SharerMultipleSvg />,
  [SpaceType.Penthouse]: <PenthouseSvg />,
  [SpaceType.LKD]: <SharerSvg />,
  [SpaceType.Bedroom]: <StudioSvg />
});
