import { TimeRange } from '../../SiteTemperatureChartWidget';
import dayjs from 'dayjs';
import RangePicker from '@components/core/RangePicker';

type PropTypes = {
  range: TimeRange;
  onChange: (range: TimeRange) => void;
}

const CustomeSelect = ({ range, onChange }: PropTypes) => {

  return (
    <RangePicker
      value={[range.from.toDate(), range.to.toDate()]}
      onChange={timeRange => timeRange && onChange({ from: dayjs(timeRange.from).startOf('day'), to: dayjs(timeRange.to).endOf('day') })}
      allowClear={false}
    />
  );
};

export default CustomeSelect;