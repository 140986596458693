import SpacesWithMetricsQuery from '@api/queries/metrics/SpacesWithMetricsQuery';
import SpaceCard from '@pages/site/overview/v2/components/space-card/SpaceCard';
import useInterval from '@hooks/useInterval';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@components/core/Button';
import FullScreenToggle from '@components/core/FullScreenToggle';
import LoadingWidget from '@components/core/LoadingWidget';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { Switch } from '@components/core/Switch';
import { Title } from '@components/core/Title';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@hooks/useApiState';
import { usePopover } from '@hooks/usePopover';
import { useBuildingContext } from '@pages/site/buildings/BuildingProvider';
import { useLayoutContext } from '@contexts/LayoutContext';
import { transparentize } from 'polished';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FloorDashboard = () => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const { floor } = useBuildingContext();
  const { allWidgetMetricTypes } = useDeviceConfigContext();
  const [showDeltas, setShowDeltas] = useState(false);
  const { visible, toggle, ref } = usePopover({});

  const { data: spaces, loading, execute, setData } = useApiState({
    query: floor && new SpacesWithMetricsQuery(floor.id, allWidgetMetricTypes, true),
    errorMessage: 'Failed to load spaces',
    immediate: false,
    initialState: []
  }, [floor]);

  const masonryBreakpointColumns = useMemo(() => {
    if (isFullScreen) {
      return {
        default: 3,
        1600: 3,
        1400: 2,
        1200: 2,
        900: 1
      }
    }

    return {
      default: 3,
      1600: 2,
      1400: 2,
      1200: 1,
      900: 1
    };
  }, [isFullScreen]);

  /**
   * Refresh metrics every 60 seconds
   */
  useInterval(() => {
    execute();
  }, 60000, [floor]);

  /**
   * Set spaces to an empty array when the floor changes
   * so that the loading spinner shows on a floor change.
   */
  useEffect(() => {
    setData([]);
  }, [floor, setData]);

  /**
   * Show the loading spinner only when the selected floor changes but not when the interval triggers the api call and sets loading to true, 
   * hence the additional check if the spaces array is empty as well (which is only the case when a new floor is selected).
   */
  if (loading && spaces.length === 0) {
    return (
      <LoadingWidget
        label={t('LoadingSpaces', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  const SettingsPopoverContent = (
    <PopoverWrapper>
      <PopoverTitle>{t('Floor.EnergyConsumption', { ns: 'organisms' })}</PopoverTitle>
      <PopoverRow>
        {t('Floor.Last7Days', { ns: 'organisms' })}

        <Switch
          checked={showDeltas}
          onChange={setShowDeltas}
        />
      </PopoverRow>
    </PopoverWrapper>
  );

  return (
    <PaddedContainer>
      <FlexRow>
        <Title
          text={floor?.name}
          size='sm'
        />

        <div style={{ marginLeft: 'auto' }} />

        <FullScreenToggle />

        <Popover
          ref={ref}
          visible={visible}
          popoverContent={SettingsPopoverContent}
          placement={PopoverPlacement.BottomRight}
          alignWithTarget={true}
          hideTriangle={true}
          offsetY={-5}
        >
          <Button
            tertiary
            circle
            label={<SettinsIcon icon={solid('gear')} />}
            onClick={toggle}
          />
        </Popover>

        <Link to='floorplan'>
          <Button
            secondary
            label={<><MapIcon icon={solid('map')} /> {t('ViewFloorPlan', { ns: 'common' })}</>}
          />
        </Link>
      </FlexRow>

      <Masonry
        breakpointCols={masonryBreakpointColumns}
        className="floor-dashboard-masonry-grid"
        columnClassName="floor-dashboard-masonry-grid_column"
      >
        {spaces?.map((space) => (
          <Card key={space.id}>
            <SpaceCard
              space={space}
              linkPathToSpace={`space/${space.id}`}
              showDeltas={showDeltas}
            />
          </Card>
        ))}
      </Masonry>
    </PaddedContainer>
  );
};

export default FloorDashboard;

const Card = styled.div`
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 5px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.shadows.medium};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const SettinsIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.palette.primary};
  cursor: pointer;
`;

const PopoverWrapper = styled.div`
  width: 240px;
  padding: 15px 15px;
`;

const PopoverRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  color: ${p => transparentize(0, p.theme.palette.text.fair)};
`;

const PopoverTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => transparentize(0.2, p.theme.palette.text.medium)};
  margin-bottom: 5px;
`;

const MapIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;
`;