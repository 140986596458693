import { DefaultTheme, useTheme } from 'styled-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chart, ChartTypeRegistry } from 'chart.js';
import { DailyTemperaturesDto } from '@api/models/DailyTemperaturesDto';
import { constructChartOptions } from '@pages/site/overview/v2/components/temperature-graph/chart/chartOptions';
import { LocalisationFunction, useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import { SiteTemperatureChartDto } from '@api/models/SiteTemperatureChartDto';
import dayjs from 'dayjs';
import { transparentize } from 'polished';
import { TimeRange } from '../SiteTemperatureChartWidget';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TimeRangeType } from '../interactions/TimeRangeSelect';

const formatValue = (value: string | number | null, toLocale: LocalisationFunction): number => {
  return value === null ? NaN : toLocale(MetricType.Temperature, value as number, { round: 1 });
};

const createDataPoint = (data: DailyTemperaturesDto, key: string, toLocale: LocalisationFunction, yearOffset = 0) => {
  return ({
    x: dayjs(data.day).add(yearOffset, 'year').format('YYYY-MM-DD'),
    y: formatValue(data[key as keyof DailyTemperaturesDto], toLocale),
    date: data.day
  });
};

const generateLabels = (range: TimeRange) => {
  const dateArray: string[] = [];
  let current = dayjs(range.from);

  while (current <= range.to) {
    dateArray.push(current.format('YYYY-MM-DD'));
    current = current.add(1, 'day');
  }

  return dateArray;
}

const getLabel = (t: TFunction, key: string) => {
  switch (key) {
    case 'maximum':
      return t('SiteOverview.TemperatureGraph.WarmestSpace', { ns: 'molecules' });

    case 'average':
      return t('SiteOverview.TemperatureGraph.SiteAverage', { ns: 'molecules' });

    case 'ninetiethPercentileAverage':
      return t('SiteOverview.TemperatureGraph.AvgTempInTopTenSpaces', { ns: 'molecules' });

    case 'outdoorAverage':
      return t('SiteOverview.TemperatureGraph.ExternalTemp', { ns: 'molecules' });

    default:
      return '';
  }
};

const getDatasetProperties = (key: string, theme: DefaultTheme, group: DatasetGroup, transparency = 0) => {
  let color = theme.primary.light;

  switch (key) {
    case 'maximum':
      color = theme.primary.light;
      break;

    case 'average':
      color = theme.complimentary.green.main;
      break;

    case 'ninetiethPercentileAverage':
      color = theme.complimentary.orange.dark;
      break;

    case 'outdoorAverage':
      color = theme.complimentary.purple.main;
      break;

    default:
      break;
  }

  return {
    legend: {
      color: transparentize(transparency, color),
      group: group,
      order: 1
    },
    borderColor: transparentize(transparency, color),
    pointBorderColor: transparentize(0.8, color),
    pointBackgroundColor: transparentize(transparency, color)
  };
};

type TChart = Chart<keyof ChartTypeRegistry, TTempGraphDataPoint[], string>;

export enum DatasetGroup {
  ThisYear = 'ThisYear',
  LastYear = 'LastYear'
}

export type TTempGraphDataPoint = {
  x: string;
  y: number;
  date: string;
}

export type TTempGraphDataset = {
  id: string;
  label: string;
  data: TTempGraphDataPoint[];
  dataUnit: string;
  legend: {
    color: string;
    group: DatasetGroup;
    order: number;
  };
}

type PropTypes = {
  data: SiteTemperatureChartDto;
  timeRangeType: TimeRangeType;
  range: TimeRange;
  showPreviousYear: boolean;
};

const useTemperatureChart = ({ data, timeRangeType, range, showPreviousYear }: PropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { localisation, toLocale, getUnit } = useLocalisation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<TChart>();
  const [labels, setLabels] = useState<string[]>();
  const [hiddenDatasets, setHiddenDatasets] = useState<{ [key: string]: string }>({});
  const [allDatasets, setAllDatasets] = useState<TTempGraphDataset[]>();
  const [visibleDatasets, setVisibleDatasets] = useState<TTempGraphDataset[]>();
  const isSmallRange = useMemo(() => range.to.diff(range.from, 'day') < 14, [range]);

  const constructData = useCallback((): { labels: string[], datasets: TTempGraphDataset[] } => {
    const labels = generateLabels(range);
    const currentYearData = data.currentYear;
    const previousYearData = data.previousYear;

    // Dynamically create datasets
    const keysToRenderOnChart = ['maximum', 'average', 'ninetiethPercentileAverage', 'outdoorAverage'];

    const currentYearDatasets: TTempGraphDataset[] = keysToRenderOnChart
      .map((key, i) => ({
        id: `currentYear-${i}`,
        label: getLabel(t, key),
        dataUnit: getUnit(MetricType.Temperature),
        data: currentYearData.map(day => createDataPoint(day, key, toLocale)),
        ...getDatasetProperties(key, theme, DatasetGroup.ThisYear),
      }));

    const previousYearDatasets: TTempGraphDataset[] = keysToRenderOnChart
      .map((key, i) => ({
        id: `previousYear-${i}`,
        label: getLabel(t, key),
        dataUnit: getUnit(MetricType.Temperature),
        data: previousYearData.map(day => createDataPoint(day, key, toLocale, 1)),
        ...getDatasetProperties(key, theme, DatasetGroup.LastYear, 0.3),
        borderDash: [6, 2]
      }));

    return {
      labels: labels,
      datasets: [...previousYearDatasets, ...currentYearDatasets].filter(x => x.data.length > 0),
    }
  }, [data, range, theme, toLocale, getUnit, t]);

  // Generate datasets and labels
  useEffect(() => {
    const data = constructData();

    setAllDatasets(data.datasets);
    setLabels(data.labels);
  }, [constructData]);

  // Include or exclude datasets from previous year
  useEffect(() => {
    const filtered = allDatasets?.filter(x => showPreviousYear || x.legend.group === DatasetGroup.ThisYear);
    setVisibleDatasets(filtered);
    setHiddenDatasets({});
  }, [allDatasets, showPreviousYear]);

  /**
   * Modify the object containing keys (the dataset id) of the hidden datasets as they are selected/deselected in the legend.
   */
  const toggleDataset = useCallback((id: string) => {
    const hidden = { ...hiddenDatasets };
    const arrayIndex = hidden[id];

    if (arrayIndex) {
      delete hidden[id];
    } else {
      hidden[id] = id;
    }

    setHiddenDatasets(hidden);
  }, [hiddenDatasets]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');

    const xAxisMin = labels?.[0];
    const xAxisMax = labels?.[labels.length - 1];

    if (context && labels && visibleDatasets) {
      const chart = new Chart(context, {
        type: 'line',
        data: { labels: labels, datasets: visibleDatasets },
        options: constructChartOptions(theme, localisation, timeRangeType, isSmallRange, xAxisMin, xAxisMax)
      });

      setChart(chart);
      return () => chart.destroy();
    }
  }, [visibleDatasets, labels, theme, localisation, isSmallRange, timeRangeType]);

  /**
   * Show/hide datasets from the chart as they are selected/deselected in the legend component.
   */
  useEffect(() => {
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        dataset.hidden = !!hiddenDatasets[(dataset as unknown as TTempGraphDataset).id];
      });

      try {
        chart.update();
      } catch (error) {
        return;
      }
    }
  }, [chart, hiddenDatasets]);

  return {
    canvasRef,
    datasets: visibleDatasets,
    hiddenDatasets,
    toggleDataset
  };
};

export default useTemperatureChart;