import styled, { useTheme } from 'styled-components';
import { TimeRange } from '../SiteTemperatureChartWidget';
import YearSelect from './time-range-selects/YearSelect';
import MonthSelect from './time-range-selects/MonthSelect';
import CustomeSelect from './time-range-selects/CustomSelect';
import { useCallback, useMemo } from 'react';
import ToggleV2, { ToggleOption } from '@components/core/ToggleV2';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@components/checkbox/Checkbox';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';

export enum TimeRangeType {
  LastSevenDays = 'LastSevenDays',
  Year = 'Year',
  Month = 'Month',
  Custom = 'Custom'
}

const startOfSeptember = dayjs().month(8).date(1).startOf('day');

export const DefaultTimeRanges: { [key in TimeRangeType]: TimeRange } = {
  [TimeRangeType.LastSevenDays]: { from: dayjs().add(-7, 'days').startOf('day'), to: dayjs().add(-1, 'days').endOf('day') },
  [TimeRangeType.Month]: { from: dayjs().startOf('month'), to: dayjs().endOf('month') },
  [TimeRangeType.Year]: { from: startOfSeptember.add(-1, 'year'), to: startOfSeptember.add(-1, 'millisecond') },
  [TimeRangeType.Custom]: { from: dayjs().add(-7, 'days').startOf('day'), to: dayjs().add(-1, 'days').endOf('day') }
}

type PropTypes = {
  timeRangeType: TimeRangeType;
  range: TimeRange;
  showPreviousYear: boolean;
  onChange: (timeRangeType: TimeRangeType, range: TimeRange, showPreviousYear: boolean) => void;
}

const TimeRangeSelect = ({ timeRangeType, range, showPreviousYear, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const theme = useTheme();

  const handleChange = useCallback((range: TimeRange) => {
    onChange(timeRangeType, range, showPreviousYear);
    trackAction('choose_time', 'performance_dates', { time_type: timeRangeType });
  }, [timeRangeType, showPreviousYear, onChange, trackAction]);

  const options: ToggleOption<{ type: TimeRangeType, selectElement?: JSX.Element }>[] = useMemo(() => [
    {
      label: t('LastSevenDays', { ns: 'common' }),
      value: { type: TimeRangeType.LastSevenDays },
      analytics: { button: 'last_7_days', category: 'performance_dates' }
    },
    {
      label: t('Month', { ns: 'common' }),
      value: { type: TimeRangeType.Month, selectElement: <MonthSelect range={range} onChange={handleChange} /> },
      analytics: { button: 'month', category: 'performance_dates' }
    },
    {
      label: t('Year', { ns: 'common' }),
      value: { type: TimeRangeType.Year, selectElement: <YearSelect range={range} onChange={handleChange} /> },
      analytics: { button: 'year', category: 'performance_dates' }
    },
    {
      label: t('Custom', { ns: 'common' }),
      value: { type: TimeRangeType.Custom, selectElement: <CustomeSelect range={range} onChange={handleChange} /> },
      analytics: { button: 'custom_dates', category: 'performance_dates' }
    }
  ], [range, handleChange, t]);

  const selectedOption = useMemo(() => (
    options.find(x => x.value.type === timeRangeType)
  ), [options, timeRangeType]);

  if (!selectedOption) {
    return null;
  }

  return (
    <FlexRow>
      <ToggleV2
        selected={selectedOption.value}
        options={options}
        onChange={selected => onChange(selected.type, DefaultTimeRanges[selected.type], showPreviousYear)}
      />

      <FlexRow style={{ gap: 16 }}>
        <Checkbox
          checked={showPreviousYear}
          onChange={checked => onChange(timeRangeType, range, checked)}
          label={t('CompareLastYear', { ns: 'common' })}
          labelStyle={{ color: theme.text.secondary, fontSize: 14 }}
          wrapperStyle={{ marginLeft: 'auto', gap: 6 }}
        />

        {selectedOption.value.selectElement}
      </FlexRow>
    </FlexRow>
  );
};

export default TimeRangeSelect;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
`;