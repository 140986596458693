import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { TimeRange } from '../temperature-graph/SiteTemperatureChartWidget';
import ToggleV2, { ToggleOption } from '@components/core/ToggleV2';
import styled from 'styled-components';
import RangePicker from '@components/core/RangePicker';
import { TOutlierConfig } from './OutlierWidget';

export enum TimeRangeType {
  LastSevenDays = 'LastSevenDays',
  Custom = 'Custom'
}

export const DefaultTimeRanges: { [key in TimeRangeType]: TimeRange } = {
  [TimeRangeType.LastSevenDays]: { from: dayjs().add(-7, 'days').startOf('day'), to: dayjs().add(-1, 'days').endOf('day') },
  [TimeRangeType.Custom]: { from: dayjs().add(-7, 'days').startOf('day'), to: dayjs().add(-1, 'days').endOf('day') }
}

type PropTypes = {
  config: TOutlierConfig;
  onChange: (config: TOutlierConfig) => void;
}

const TimeRangeSelect = ({ config, onChange }: PropTypes) => {
  const { t } = useTranslation();

  const options: ToggleOption<TimeRangeType>[] = useMemo(() => [
    {
      label: t('LastSevenDays', { ns: 'common' }),
      value: TimeRangeType.LastSevenDays,
      analytics: { button: 'last_7_days', category: 'overview_outliers', props: { metric: config.metric, time_span: TimeRangeType.LastSevenDays, filter: config.filter?.type } }
    },
    {
      label: t('Custom', { ns: 'common' }),
      value: TimeRangeType.Custom,
      analytics: { button: 'custom_dates', category: 'overview_outliers', props: { metric: config.metric, time_span: TimeRangeType.Custom, filter: config.filter?.type } }
    }
  ], [t, config]);

  return (
    <FlexRow>
      <ToggleV2
        selected={config.timeRangeType}
        options={options}
        onChange={type => onChange({
          ...config,
          timeRangeType: type,
          timeRange: DefaultTimeRanges[type]
        })}
      />

      {config.timeRangeType === TimeRangeType.Custom &&
        <RangePicker
          value={[config.timeRange.from.toDate(), config.timeRange.to.toDate()]}
          onChange={range => range && onChange({
            ...config,
            timeRange: { from: dayjs(range.from).startOf('day'), to: dayjs(range.to).endOf('day') }
          })}
          allowClear={false}
        />
      }
    </FlexRow>
  );
};

export default TimeRangeSelect;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
`;