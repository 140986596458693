import { BuildingHierarchiesGetBySiteId } from '@api/queries/buildings/BuildingHierarchiesGetBySiteId';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { HierarchyBuilding, HierarchyFloor, HierarchySpace } from '@api/models/Hierarchy';
import SpaceClimateControlUpdateCommand from '@api/queries/climate-control/Space/SpaceClimateControlUpdateCommand';
import { IInternalNode, INodeGroup } from '@components/cascader-single-select/CascaderSingleSelect.types';
import CascaderModal from '@components/cascader-single-select/Modal/CascaderModal';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { useModal } from '@hooks/useModal';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IAddException {
  siteClimateControl: SiteClimateControlDto;
  exceptions: SpaceClimateControlDto[];
  onExceptionAdded: () => void;
}

const AddException = ({ siteClimateControl, exceptions, onExceptionAdded }: IAddException) => {
  const { t } = useTranslation();
  const { execute, loading } = useApi();
  const { isOpen: isModalOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const [cascaderOptions, setCascaderOptions] = useState<INodeGroup<HierarchySpace>>();

  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesGetBySiteId(siteClimateControl.siteId),
    initialState: [],
  }, [siteClimateControl]);

  useEffect(() => {
    const spacesWithClimateControl = siteClimateControl.spaceClimateControls.map(x => x.spaceId);
    const spacesWithExceptions = exceptions.map(x => x.spaceId);

    // Only enable spaces to be selected that have climate control are not already an exception.
    const relevantSpaces = spacesWithClimateControl.filter(x => !spacesWithExceptions.includes(x));

    // Filter buildings, floors and spaces to only show those that are climate controlled.
    const relevantBuildings = buildings.reduce((filteredBuildings, building) => {
      const floors = building.floors.reduce((filteredFloors, floor) => {
        const spaces = floor.spaces.filter(space => relevantSpaces.includes(space.id));

        if (spaces.length > 0) {
          filteredFloors.push({ ...floor, spaces: spaces });
        }

        return filteredFloors;
      }, [] as HierarchyFloor[]);

      if (floors.length > 0) {
        filteredBuildings.push({ ...building, floors: floors })
      }

      return filteredBuildings;
    }, [] as HierarchyBuilding[]);

    // Create Cascader options
    const options: INodeGroup<HierarchySpace> = {
      header: t('Buildings', { ns: 'assets' }),
      nodes: orderBy(relevantBuildings, x => x.name.toLocaleLowerCase()).map((building) => ({
        label: building.name,
        childGroup: {
          header: t('Floors', { ns: 'assets' }),
          nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
            label: floor.name,
            childGroup: {
              header: t('Spaces', { ns: 'assets' }),
              nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map((space) => ({
                label: space.name,
                value: space,
                selectable: true,
                disabled: !relevantSpaces.includes(space.id)
              }))
            }
          })),
        },
      })),
    };

    setCascaderOptions(options);
  }, [buildings, siteClimateControl, exceptions, t])

  const handleConfirm = (node?: IInternalNode<HierarchySpace>) => {
    const spaceId = node?.value?.id;
    const spaceClimateControl = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId);

    if (spaceClimateControl) {
      addException(spaceClimateControl);
    }
  }

  const addException = async (spaceClimateControl: SpaceClimateControlDto) => {
    const latestException = exceptions[exceptions.length - 1];
    const values: SpaceClimateControlDto | SiteClimateControlDto = latestException ?? siteClimateControl;

    const modified = {
      ...spaceClimateControl,
      minTemp: values.minTemp,
      maxTemp: values.maxTemp,
      targetTemp: values.targetTemp,
      controlMode: values.controlMode
    }

    await execute({
      query: new SpaceClimateControlUpdateCommand(modified, true),
      successMessage: t('ExceptionAddSuccess', { ns: 'status' }),
      errorMessage: t('ExceptionAddError', { ns: 'status' }),
      pendingMessage: t('ExceptionAddPending', { ns: 'status' }),
    });

    onExceptionAdded();
  };

  return (
    <>
      <CascaderModal
        modalRef={modalRef}
        open={isModalOpen}
        group={cascaderOptions}
        mustSelectValue
        onConfirm={handleConfirm}
        onCancel={toggleModal}
        toggleModal={toggleModal}
        confirmButton={t('ClimateControl.AddException', { ns: 'molecules' })}
        cancelButton={t('Cancel', { ns: 'common' })}
      />

      <FlexRow style={{ paddingTop: exceptions.length === 0 ? '10px' : '20px' }}>
        {cascaderOptions && cascaderOptions.nodes.length > 0 &&
          <>
            <Label>
              {t('ClimateControl.AddSpace', { ns: 'molecules' })}
            </Label>

            <AddExceptionButton
              data-cy={'ClimateControl_PlusButton'}
              icon={solid('plus')}
              onClick={() => !loading && toggleModal()}
            />
          </>
        }
      </FlexRow>
    </>
  );
};

export default AddException;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: ${p => p.theme.palette.text.fair};
  padding: 10px 0;
`;

export const AddExceptionButton = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.palette.text.fair};
  padding: 8px;
  background: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 2px 4px 0 ${p => p.theme.palette.shadows.strong};
  border-radius: 6px;
  cursor: pointer;
`;