import { Button } from '@components/core/Button';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

type ClimateControlWidgetSavingBannerProps = {
  onSave: () => void;
  onCancel: () => void;
  isVisible?: boolean;
}

const ClimateControlWidgetSavingBanner = ({ onSave, onCancel, isVisible }: ClimateControlWidgetSavingBannerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledBanner isVisible={isVisible}>
      {t('SaveChanges', { ns: 'validation' })}?

      <Button
        cypress_id={'ClimateControl_ResetButton'}
        tertiary
        label={t('Cancel', { ns: 'common' })}
        onClick={onCancel}
        style={{ margin: '0 10px 0 auto', padding: '5px 12px' }}
        color={theme.palette.text.fair}
      />
      <Button
        cypress_id={'ClimateControl_SaveButton'}
        label={t('Save', { ns: 'common' })}
        onClick={onSave}
        style={{ padding: '5px 12px' }}
      />
    </StyledBanner>
  );
};

export default ClimateControlWidgetSavingBanner;

export const StyledBanner = styled.div<{ isVisible?: boolean }>`
  height: 0px;
  opacity: 0;
  overflow: hidden;
  border: none;

  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  padding: 0 15px;

  border-radius: 5px;
  transition: opacity 400ms ease;

  display: flex;
  align-items: center;

  ${p => p.isVisible && css`
    height: 50px;
    opacity: 1;
    border: 1px solid ${p => p.theme.palette.borders.weak};
    border-left: 4px solid ${p => p.theme.palette.red};
    box-shadow: 0px 3px 8px ${p => p.theme.palette.shadows.medium};
    margin-top: 20px;
  `}
`;