import { useTranslation } from 'react-i18next';
import PreviewBanner from '@components/core/PreviewBanner';
import previewImageDark from '@assets/images/utility-summary-preview-dark.svg';
import previewImage from '@assets/images/utility-summary-preview.svg';
import { useThemeContext } from '@contexts/ThemeContext/ThemeContext';

const UtilitiesSummaryPreview = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useThemeContext();

  return (
    <PreviewBanner
      feature={'Utility summary'}
      heading={t('SiteOverview.UtilityPreview.Heading', { ns: 'molecules' })}
      bulletPoints={[
        t('SiteOverview.UtilityPreview.P1', { ns: 'molecules' }),
        t('SiteOverview.UtilityPreview.P2', { ns: 'molecules' }),
        t('SiteOverview.UtilityPreview.P3', { ns: 'molecules' }),
        t('SiteOverview.UtilityPreview.P4', { ns: 'molecules' })
      ]}
      image={isDarkTheme ? previewImageDark : previewImage}
      imageStyles={{ marginBottom: 'unset' }}
    />
  );
};

export default UtilitiesSummaryPreview;