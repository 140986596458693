import { PatchQuery } from '../../common/PatchQuery';
import { ClimateControlFallbackConfig, SiteClimateControlDto } from '@api/models/SiteClimateControlDto';

export default class SiteClimateControlUpdateFallbackSettingsCommand extends PatchQuery<SiteClimateControlDto> {
  noMotionRulesEnabled: boolean;
  noMotionThreshold1: number;
  noMotionThreshold1Temp: number;
  noMotionThreshold2: number;
  noMotionThreshold2Temp: number;
  offlineSensorThreshold: number;
  offlineSensorTemp: number;

  constructor(id: number, config: ClimateControlFallbackConfig) {
    super(id);
    this.noMotionRulesEnabled = config.noMotionRulesEnabled;
    this.noMotionThreshold1 = config.noMotionThreshold1;
    this.noMotionThreshold1Temp = config.noMotionThreshold1Temp;
    this.noMotionThreshold2 = config.noMotionThreshold2;
    this.noMotionThreshold2Temp = config.noMotionThreshold2Temp;
    this.offlineSensorThreshold = config.offlineSensorThreshold;
    this.offlineSensorTemp = config.offlineSensorTemp;
  }

  public targetUrl(): string {
    return '/ClimateControl/Site/UpdateFallbackSettings';
  }
}