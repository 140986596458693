import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@api/enums/MetricType';
import { RangeStatus } from '@api/enums/RangeStatus';
import { TemperatureControlDeviceStatus } from '@api/enums/TemperatureControlDeviceStatus';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { SpaceClimateControlDetailsDto } from '@api/models/SpaceClimateControlDetailsDto';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { HierarchyBuilding, HierarchySpace } from '@api/models/Hierarchy';
import SpaceClimateControlUpdateCommand from '@api/queries/climate-control/Space/SpaceClimateControlUpdateCommand';
import { DeleteDialog } from '@components/core/DeleteDialog';
import NoData from '@components/core/NoData';
import { Modal } from '@components/core/Modal';
import { Table } from '@components/table/Table';
import { ITableColumn, TableSortOrder } from '@components/table/Table.types';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@hooks/useApi';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useModal } from '@hooks/useModal';
import { dateToUtcDate } from '@utils/DateUtils';
import { includesCI } from '@utils/StringUtils';
import { formatDistance } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { DeleteSpaceClimateControlExceptionQuery, filterBuildingsByRelevantSpaces } from '../../../ClimateControlUtils';
import CreateException from '../../setup/space-exceptions/CreateException';
import EditException from '../../setup/space-exceptions/EditException';
import { ExceptionForm } from '../../setup/types/ExceptionForm';
import SpaceDetailsModal from '../space-details-modal/SpaceDetailsModal';
import TableActions from './TableActions';
import TableHeader from './TableHeader';
import TextHighlighter from '@components/table/TextHighlighter';

type SpacesTableProps = {
  tableData: SpaceClimateControlDetailsDto[];
  siteClimateControl: SiteClimateControlDto;
  buildings: HierarchyBuilding[];
  emptyListLabel?: string;
  refreshSiteClimateControl: () => void;
}

const SpacesTable = ({ tableData, siteClimateControl, buildings, emptyListLabel, refreshSiteClimateControl }: SpacesTableProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { getUnit, toLocale, fromLocale } = useLocalisation();
  const { isOpen: detailsDialogIsOpen, toggle: toggleDetailsDialog, ref: detailsDialogRef } = useModal({});
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});
  const { isOpen: createDialogIsOpen, toggle: toggleCreateDialog, ref: createDialogRef } = useModal({ disableCloseOnClickOutside: true });
  const { isOpen: editDialogIsOpen, toggle: toggleEditDialog, ref: editDialogRef } = useModal({ disableCloseOnClickOutside: true });
  const [searchString, setSearchString] = useState<string>('');
  const [stepDownFilterEnabled, setStepDownFilterEnabled] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState<HierarchyBuilding[] | undefined>([]);
  const [statusFilter, setStatusFilter] = useState<TemperatureControlDeviceStatus[]>([]);
  const [selectedDetails, setSelectedDetails] = useState<SpaceClimateControlDetailsDto>();
  const [selectedSpace, setSelectedSpace] = useState<HierarchySpace>();
  const [selectedException, setSelectedException] = useState<SpaceClimateControlDto>();
  const [relevantBuildings, setRelevantBuildings] = useState<HierarchyBuilding[]>([]);
  const exceptionFormMethods = useForm<ExceptionForm>({ mode: 'onChange' });
  const onMobile = useMediaQuery('(max-width: 900px)');
  const unit = getUnit(MetricType.Temperature);
  const theme = useTheme();
  const navigate = useNavigate();

  const hasFilters = useMemo(() => (
    (buildingFilter && buildingFilter.length > 0) || statusFilter.length > 0 || stepDownFilterEnabled
  ), [buildingFilter, statusFilter, stepDownFilterEnabled]);

  const memoizedSelectedExceptions = useMemo(() => {
    return selectedException ? [selectedException] : [];
  }, [selectedException]);

  useEffect(() => {
    const spacesWithClimateControl = siteClimateControl.spaceClimateControls.map(x => x.spaceId);
    const spacesWithExceptions = tableData.map(x => x.isAnException ? x.spaceId : undefined).filter(x => x !== undefined);
    const relevantSpaces = spacesWithClimateControl.filter(x => !spacesWithExceptions.includes(x));
    const filteredBuildings = filterBuildingsByRelevantSpaces(buildings, relevantSpaces);
    setRelevantBuildings(filteredBuildings);
  }, [buildings, siteClimateControl.spaceClimateControls, tableData]);

  const searchFilter = useCallback((x: SpaceClimateControlDetailsDto) => {
    return includesCI(x.spaceName, searchString)
      || includesCI(x.floorName, searchString)
      || includesCI(x.buildingName, searchString);
  }, [searchString]);

  const stepDownModeFilter = useCallback((x: SpaceClimateControlDetailsDto) => {
    return !stepDownFilterEnabled || !!x.stepDownMode;
  }, [stepDownFilterEnabled]);

  const locationFilter = useCallback((x: SpaceClimateControlDetailsDto) => {
    return (buildingFilter && x.buildingId) ? buildingFilter.map(x => x.id).includes(x.buildingId) : true;
  }, [buildingFilter]);

  const statusFilters = useCallback((x: SpaceClimateControlDetailsDto) => {
    if (statusFilter.length > 0) {
      return x.deviceStatus ? statusFilter.includes(x.deviceStatus) : true;
    } else {
      return true;
    }
  }, [statusFilter]);

  const renderSpaceName = useCallback((e: SpaceClimateControlDetailsDto) => {
    return (
      <SpaceRow>
        <TextHighlighter
          text={e.spaceName}
          highlight={searchString}
        />

        {e.isAnException && <Exception>{t('Exception', { ns: 'common' })}</Exception>}
      </SpaceRow>)
  }, [searchString, t]);

  const renderOverviewActions = useCallback((spaceDetails: SpaceClimateControlDetailsDto) => {
    return (
      <TableActions
        spaceDetails={spaceDetails}
        onViewDetails={() => {
          setSelectedDetails(spaceDetails);
          toggleDetailsDialog();
        }}
        onNavigateToSpace={(spaceDetails: SpaceClimateControlDetailsDto) => navigate(`./../building/${spaceDetails.buildingId}/floor/${spaceDetails.floorId}/space/${spaceDetails.spaceId}`)}
        onCreateException={(spaceId: number) => {
          const space = buildings.flatMap(x => x.floors).flatMap(x => x.spaces).find(x => x.id === spaceId);
          setSelectedDetails(undefined);
          if (space) {
            setSelectedSpace(space);
            toggleCreateDialog();
          }
        }}
        onEditException={(spaceId: number) => {
          const exception = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId);
          setSelectedDetails(undefined);
          if (exception) {
            setSelectedException(exception);
            toggleEditDialog();
          }
        }}
      />
    )
  }, [buildings, navigate, siteClimateControl.spaceClimateControls, toggleCreateDialog, toggleDetailsDialog, toggleEditDialog]);

  const renderTempValue = useCallback((value?: number) => {
    if (value === undefined || value === null) {
      return (
        <>
          <Icon icon={solid('warning')} color={theme.warning.main} style={{ height: 11 }} />
          <NoDataLabel>{t('NoData', { ns: 'status' })}</NoDataLabel>
        </>
      );
    }

    return (
      <Actual>
        {toLocale(MetricType.Temperature, value, { round: 1 })} {unit}
      </Actual>
    );
  }, [theme, toLocale, unit, t]);

  const renderDeviceTemp = useCallback((e: SpaceClimateControlDetailsDto) => {
    if (e.deviceTemp === undefined || e.deviceTemp === null) {
      return (
        <>
          <Icon icon={solid('warning')} color={theme.warning.main} style={{ height: 11 }} />
          <NoDataLabel>{t('NoData', { ns: 'status' })}</NoDataLabel>
        </>
      );
    }

    let icon = solid('dash');
    let color = theme.complimentary.green.main;

    if (e.rangeStatus === RangeStatus.Over) {
      icon = solid('chevrons-up');
      color = theme.palette.red;
    } else if (e.rangeStatus === RangeStatus.Under) {
      icon = solid('chevrons-down');
      color = theme.complimentary.blue.main;
    }

    return (
      e.deviceTemp ? (
        <Actual>
          {toLocale(MetricType.Temperature, e.deviceTemp, { round: 1 })} {unit}
          <Icon icon={icon} color={color} />
        </Actual>
      ) : (
        <div>-</div>
      )
    );
  }, [theme, toLocale, unit, t]);

  const onDeleteException = async (spaceId: number) => {
    const exception = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId);
    if (exception) {
      await execute({
        query: DeleteSpaceClimateControlExceptionQuery(exception),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
      });
      toggleDeleteDialog();
      setSelectedDetails(undefined);
      refreshSiteClimateControl();
    }
  }

  const onEditException = async () => {
    const exceptionFormValues = exceptionFormMethods.getValues();
    const spaceId = exceptionFormValues.spaceIds[0];
    const exception = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId)
    if (exception) {
      const modified = {
        ...exception,
        minTemp: fromLocale(MetricType.Temperature, exceptionFormValues.minTemp),
        maxTemp: fromLocale(MetricType.Temperature, exceptionFormValues.maxTemp),
        targetTemp: fromLocale(MetricType.Temperature, exceptionFormValues.targetTemp),
        controlMode: exceptionFormValues.controlMode,
        endDate: exceptionFormValues.endDate ? dateToUtcDate(exceptionFormValues.endDate).toISOString() : undefined
      }

      await execute({
        query: new SpaceClimateControlUpdateCommand(modified, true),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' })
      });

      refreshSiteClimateControl();
      exceptionFormMethods.reset();
    }
  }

  const tableColumns: ITableColumn<SpaceClimateControlDetailsDto>[] = useMemo(() => ([
    {
      label: t('Space', { ns: 'common' }),
      key: 'spaceName',
      customElement: (e) => renderSpaceName(e),
      propagateRowClickOnCustomElement: true
    },
    {
      label: t('Floor', { ns: 'common' }),
      key: 'floorName',
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName',
    },
    {
      label: t('DeviceTarget', { ns: 'common' }),
      key: 'targetTemp',
      customElement: (e: SpaceClimateControlDetailsDto) => renderTempValue(e.targetTemp),
    },
    {
      label: t('DeviceActual', { ns: 'common' }),
      key: 'deviceTemp',
      customElement: (e: SpaceClimateControlDetailsDto) => renderDeviceTemp(e)
    },
    {
      label: t('Multisensor', { ns: 'common' }),
      key: 'multisensorTemp',
      customElement: (e: SpaceClimateControlDetailsDto) => renderTempValue(e.multisensorTemp)
    },
    {
      label: t('Status', { ns: 'common' }),
      key: 'deviceStatus',
      customElement: space => space.deviceStatus && t(space.deviceStatus, { ns: 'enums' })
    },
    {
      label: t('MotionMeasuredOn', { ns: 'enums' }),
      key: 'lastMotionOn',
      displayFormat: e => e.lastMotionOn ? formatDistance(new Date(e.lastMotionOn), new Date(), { addSuffix: true }) : t('OverNHoursAgo', { ns: 'common', hours: 24 }),
      sortFormat: e => e.lastMotionOn && new Date(e.lastMotionOn),

    },
    {
      key: 'actions',
      customElement: (e) => renderOverviewActions(e),
      fixedWidth: '60px'
    }
  ]), [t, renderSpaceName, renderDeviceTemp, renderTempValue, renderOverviewActions]);

  if (tableData.length === 0) {
    return (
      <NoData
        icon={duotone('party-horn')}
        label={t('LookingGood', { ns: 'status' })}
        subLabel={emptyListLabel}
        large
        styles={{ margin: '100px 0' }}
      />
    )
  }

  return (
    <>
      <SpaceDetailsModal
        modalRef={detailsDialogRef}
        spaceDetails={selectedDetails}
        unit={unit}
        onClose={() => {
          toggleDetailsDialog();
          setSelectedDetails(undefined);
        }}
        isOpen={detailsDialogIsOpen}
        onDeleteException={() => {
          toggleDetailsDialog();
          toggleDeleteDialog();
        }}
        onCreateException={(spaceId: number) => {
          const space = buildings.flatMap(x => x.floors).flatMap(x => x.spaces).find(x => x.id === spaceId);
          toggleDetailsDialog();
          setSelectedDetails(undefined);
          if (space) {
            setSelectedSpace(space);
            toggleCreateDialog();
          }
        }}
        onEditException={(spaceId: number) => {
          const exception = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId);
          toggleDetailsDialog();
          setSelectedDetails(undefined);
          if (exception) {
            setSelectedException(exception);
            toggleEditDialog();
          }
        }}
      />

      <DeleteDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        header={t('ClimateControl.DeleteExceptionModalHeader', { ns: 'molecules' })}
        body={t('ClimateControl.DeleteExceptionModalContent', { ns: 'molecules' })}
        onCancel={() => toggleDeleteDialog()}
        onConfirm={() => {
          if (selectedDetails) {
            onDeleteException(selectedDetails.spaceId)
          }
        }}
      />

      <Modal
        ref={createDialogRef}
        isOpen={createDialogIsOpen}
        hide={toggleCreateDialog}
        plainModal={true}
        width={'450px'}
      >
        <FormProvider {...exceptionFormMethods}>
          <CreateException
            buildings={relevantBuildings}
            siteClimateControl={siteClimateControl}
            selectedSpace={selectedSpace}
            onCreateException={() => {
              onEditException();
              setSelectedSpace(undefined);
              toggleCreateDialog();
            }}
            onCancel={() => {
              setSelectedSpace(undefined);
              toggleCreateDialog();
            }} />
        </FormProvider>
      </Modal>

      <Modal
        ref={editDialogRef}
        isOpen={editDialogIsOpen}
        hide={toggleEditDialog}
        plainModal={true}
        width={'450px'}
      >
        <FormProvider {...exceptionFormMethods}>
          <EditException
            selectedExceptions={memoizedSelectedExceptions}
            onEditException={() => {
              onEditException();
              setSelectedException(undefined);
              toggleEditDialog();
            }}
            onCancel={() => {
              setSelectedException(undefined);
              toggleEditDialog();
            }}
            externalSensorModeEnabled={siteClimateControl.externalSensorModeEnabled} />
        </FormProvider>
      </Modal>

      <TableHeader
        tableData={tableData}
        buildings={buildings}
        hasFilters={hasFilters}
        stepDownFilterEnabled={stepDownFilterEnabled}
        siteClimateControl={siteClimateControl}
        onSearchChange={setSearchString}
        onLocationChange={setBuildingFilter}
        onStatusChange={setStatusFilter}
        onStepDownFilterChange={setStepDownFilterEnabled}
      />

      <Table
        columns={tableColumns}
        records={tableData}
        recordKey='id'
        defaultSortColumn={'deviceTemp'}
        defaultSortOrder={TableSortOrder.DESC}
        cardEffect
        disablePaging={onMobile || tableData.length < 10}
        highlightString={searchString}
        filters={[searchFilter, locationFilter, statusFilters, stepDownModeFilter]}
        removeDefaultStyling={true}
        minHeight={'100%'}
        recordBorder={true}
        showOverflow={true}
        onRowClick={(row: SpaceClimateControlDetailsDto) => {
          setSelectedDetails(row);
          toggleDetailsDialog();
        }}
      />
    </>

  )
}

export default SpacesTable;

const SpaceRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Exception = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 10px;
`;

const Actual = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NoDataLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  padding-left: 5px;
`;