import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConsumptionType } from '@api/enums/ConsumptionType';
import { useModal } from '@hooks/useModal';
import { InformationDialog } from '@components/core/InformationDialog';

interface IDayMonthToggle {
  consumptionType: ConsumptionType;
  onChange: (consumptionType: ConsumptionType) => void;
}

const DayMonthToggle = ({ consumptionType, onChange }: IDayMonthToggle) => {
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const { t } = useTranslation(['molecules']);

  const toggleConsumptionType = () => {
    onChange(consumptionType === ConsumptionType.Current ? ConsumptionType.MonthRolling : ConsumptionType.Current);
  };

  return (
    <>
      <InformationDialog
        modalRef={modalRef}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={t('EnergyWidgets.DMToggle', { ns: 'molecules' })}
        content={t('EnergyWidgets.DMToggleText', { ns: 'molecules' })}
        button={{ label: 'Close' }}
      />

      <FlexRow>
        <ToggleWrapper>
          <ToggleButton
            selected={consumptionType === ConsumptionType.Current}
            onClick={toggleConsumptionType}>
            {t('EnergyWidgets.Day', { ns: 'molecules' })}
          </ToggleButton>
          <ToggleButton
            selected={consumptionType === ConsumptionType.MonthRolling}
            onClick={toggleConsumptionType}>
            {t('EnergyWidgets.Month', { ns: 'molecules' })}
          </ToggleButton>

        </ToggleWrapper>

        <InfoIcon
          icon={regular('circle-info')}
          onClick={toggleModal}
        />
      </FlexRow>
    </>
  );
};

export default DayMonthToggle;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ToggleWrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 1px 4px ${p => p.theme.palette.shadows.medium};
  display: flex;
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  padding: 1px 8px;
  user-select: none;
  cursor: pointer;
  
  text-align: center; 
  width: 60px;
  
  font-size: 14px;
  font-weight: 400;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  color: ${p => p.theme.palette.text.weak};

  ${({ selected }) => selected && css`
    background-color: ${p => transparentize(0.1, p.theme.palette.primary)};
    color: ${p => p.theme.palette.text.onPrimary};
  `}
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  padding: 5px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;
  margin: -17px 0 0 3px;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;