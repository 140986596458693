import { Chart, ChartTypeRegistry, TooltipModel } from 'chart.js';
import { ConsumptionChartDataset, ConsumptionChartStack } from './useMonthlyChart';
import { DefaultTheme } from 'styled-components';
import { numberWithCommas } from '@utils/NumberUtils';
import { AbbreviatedToFullMonthName, getMonthIndex } from '@utils/DateUtils';
import { transparentize } from 'polished';
import { groupBy } from 'lodash';
import { LineBarChartType } from '@components/charts/Chart.types';

export type TooltipContext = { chart: Chart; tooltip: TooltipModel<keyof ChartTypeRegistry> };

// Render rounded rectangle or line icon where appropriate
const getIconElement = (dataset: ConsumptionChartDataset): HTMLSpanElement | undefined => {
  const span = document.createElement('span');
  span.style.height = '14px';
  span.style.width = '14px';
  span.style.marginRight = '8px';
  span.style.background = dataset.legend.color;
  span.style.borderRadius = '4px';
  span.style.display = 'inline-block';

  if (dataset.stack === ConsumptionChartStack.Total) {
    span.style.background = 'transparent';
    return span;
  } else if (dataset.type === LineBarChartType.Bar) {
    return span;
  } else {
    span.style.height = '3px';
    return span;
  }
};

const getOrCreateTooltip = (chart: Chart, theme: DefaultTheme) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.backgroundColor = theme.background.container;
    tooltipEl.style.border = `1px solid ${theme.action.divider}`;
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.boxShadow = `0 2px 12px -2px ${theme.shadow.dark}`;
    tooltipEl.style.color = transparentize(0.15, theme.text.primary);
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all 300ms ease';
    tooltipEl.style.fontSize = '14px';
    tooltipEl.style.width = 'max-content';
    tooltipEl.style.padding = '10px 15px';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: TooltipContext, theme: DefaultTheme, datasets: ConsumptionChartDataset[]) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, theme);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  const tableRoot = tooltipEl.querySelector('table');

  if (!tableRoot) {
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];

    // Create table header (with month name)
    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = '0';

      const th = document.createElement('th');
      th.style.borderWidth = '0';
      th.style.textAlign = 'left';
      th.style.color = theme.text.primary;
      const text = document.createTextNode(AbbreviatedToFullMonthName(title));

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    // Create table body (one line per dataset)
    const tableBody = document.createElement('tbody');

    const groupedByYear = groupBy(datasets, x => x.year);

    Object.entries(groupedByYear).forEach((group) => {
      // Render year label as a group sub heading
      const year = group[0];
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      td.style.paddingTop = '6px';
      td.style.paddingBottom = '4px';
      td.style.fontWeight = '500';
      td.style.color = theme.text.primary;
      const yearText = document.createTextNode(year);

      td.appendChild(yearText);
      tr.appendChild(td);
      tableBody.appendChild(tr);

      // Render a label for each dataset
      group[1]?.forEach((dataset) => {
        const isTotal = dataset.stack === ConsumptionChartStack.Total;

        const tr = document.createElement('tr');
        const td = document.createElement('td');
        td.style.paddingBottom = '2px';
        td.style.display = 'flex';
        td.style.alignItems = 'center';

        // Render rounded rectangle or line icon where appropriate
        const iconElement = getIconElement(dataset);
        if (iconElement) {
          td.appendChild(iconElement);
        }

        // Render label (meter category) and value
        const dataPoint = dataset.data[getMonthIndex(tooltip.title[0])];
        const value = isNaN(dataPoint) ? ' --' : `${numberWithCommas(dataPoint, 2)}${dataset.dataUnit}`;

        const labelSpan = document.createElement('span');
        labelSpan.innerHTML = `${dataset.label}: ${value}`;
        labelSpan.style.fontWeight = isTotal ? '500' : '400';
        labelSpan.style.color = isTotal ? theme.text.primary : 'inherit';
        labelSpan.style.paddingRight = '4px';
        td.appendChild(labelSpan);

        // Add cell element to row and row to table
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
    });

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.bottom = positionY + 40 + 'px';
};