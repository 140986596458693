import SiteAreaDto from '@api/models/SiteAreaDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteGetAreaQuery extends GetQuery<SiteAreaDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/sites/getsitearea/${this.id}`;
  }
}