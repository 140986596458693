import { UtilityConsumptionDto } from '@api/models/UtilityConsumptionDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteUtilitiesConsumptionGetQuery extends GetQuery<UtilityConsumptionDto[]>{
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/sites/GetSiteUtilitiesConsumption/${this.siteId}`;
  }
}