import { AddExceptionButton } from './AddException';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useApi } from '@hooks/useApi';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { WarningDialog } from '@components/core/WarningDialog';
import { useModal } from '@hooks/useModal';
import { DeleteSpaceClimateControlExceptionQuery } from '@pages/site/temperature-control/v2/ClimateControlUtils';

interface IRemoveException {
  spaceClimateControl: SpaceClimateControlDto;
  refresh: () => void;
}

const RemoveException = ({ spaceClimateControl, refresh }: IRemoveException) => {
  const { t } = useTranslation();
  const { isOpen: dialogIsOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const { execute, loading } = useApi();

  const removeException = async (spaceClimateControl: SpaceClimateControlDto) => {
    toggleDialog();

    await execute({
      query: DeleteSpaceClimateControlExceptionQuery(spaceClimateControl),
      successMessage: t('ExceptionRemoveSuccess', { ns: 'status' }),
      errorMessage: t('ExceptionRemoveError', { ns: 'status' }),
      pendingMessage: t('ExceptionRemovePending', { ns: 'status' })
    });

    refresh();
  };

  return (
    <>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={dialogIsOpen}
        sectionOne={t('ClimateControl.DeleteExceptionDialog', { ns: 'molecules' })}
        confirmButton={t('Remove', { ns: 'common' })}
        onCancel={toggleDialog}
        onConfirm={() => removeException(spaceClimateControl)}
        isLoading={loading}
      />

      <AddExceptionButton
        data-cy={'ClimateControl_MinusButton'}
        icon={solid('minus')}
        onClick={toggleDialog}
      />
    </>
  );
};

export default RemoveException;