import { FlexColumn } from '@components/core/FlexColumn';
import { OutOfDateCircle } from '@components/core/OutOfDateCircle';
import { Square } from '@components/core/Square';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceType } from '@api/enums/SpaceType';
import { SpaceType_Icon } from '@api/enums/SpaceType_Icon';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const ClimateControl_HeatmapLegend = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const squareSize = '25px';

  return (
    <Wrapper>
      <FlexColumn>
        <LegendItem>
          <Square
            size={squareSize}
            color={theme.palette.climateControlHeatmap.green}
          />
          <Label>
            {t('ClimateControl.Heatmap.WithinRange', { ns: 'molecules' })}
          </Label>
        </LegendItem>

        <LegendItem>
          <Square
            size={squareSize}
            color={theme.palette.climateControlHeatmap.red}
          />
          <Label>
            {t('ClimateControl.Heatmap.OutsideRange', { ns: 'molecules' })}
          </Label>
        </LegendItem>

        <LegendItem>
          <IconSquare
            size={squareSize}
            color={theme.palette.climateControlHeatmap.orange}
          >
            {SpaceType_Icon(SpaceType.Studio)}
          </IconSquare>
          <Label>
            {t('ClimateControl.Heatmap.TRVConfigIssue', { ns: 'molecules' })}
          </Label>
        </LegendItem>

        <LegendItem>
          <IconSquare
            size={squareSize}
            color={theme.palette.climateControlHeatmap.green}
          >
            {SpaceType_Icon(SpaceType.Studio)}
            <OutOfDateCircle size='9px' />
          </IconSquare>
          <Label>
            {t('ClimateControl.Heatmap.NoReportIn2Hours', { ns: 'molecules' })}
          </Label>
        </LegendItem>

        <LegendItem>
          <Square size={squareSize}>
            <DeviceIssueIcon icon={regular('sensor-triangle-exclamation')} />
            <OutOfDateCircle size='9px' />
          </Square>
          <Label>
            {t('ClimateControl.Heatmap.NoReportIn7Days', { ns: 'molecules' })}
          </Label>
        </LegendItem>
      </FlexColumn>
    </Wrapper>
  )
}

export default ClimateControl_HeatmapLegend

const Wrapper = styled.div`
  padding: 20px 25px;
  flex-shrink: 0;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;

const Label = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.fair};
  padding-left: 10px;
  white-space: nowrap;
`;

const DeviceIssueIcon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  color: ${p => p.theme.palette.climateControlHeatmap.grey};
`;

const IconSquare = styled(Square)`
  svg {
    width: 18px;
    height: 18px;

    * {
      stroke: #fff;
    }
  }
`;
