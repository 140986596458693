import { exportCSV } from '@utils/ExportUtils';
import { Button } from '@components/core/Button';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MonthNames } from '@utils/DateUtils';
import { UtilityData } from './UtilitiesSummary';
import { isNaN } from 'lodash';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';

const lineBreak = '\n';

const displayString = (value: number) => {
  return isNaN(value) ? '' : value;
};

type PropTypes = {
  data: UtilityData[];
  loading: boolean;
};

const CSVExport = ({ data, loading }: PropTypes) => {
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const { site } = useSiteContext();
  const { trackAction } = useAnalytics();

  const csvString = useMemo(() => {
    const now = new Date();
    const year = now.getFullYear();

    let csv = lineBreak;

    data.forEach(dto => {
      csv += t(`SiteOverview.${dto.metricType}`, { ns: 'molecules' }) + lineBreak;
      csv += `${t('Unit', { ns: 'common' })}, ${dto.unit}` + lineBreak;
      csv += lineBreak;
      csv += `${year} ${t('SiteOverview.CSVExport.ActualConsumptionYTD', { ns: 'molecules' })}, ${displayString(dto.total)}` + lineBreak;
      csv += `${year} ${t('SiteOverview.CSVExport.TargetConsumptionYTD', { ns: 'molecules' })}, ${displayString(dto.targetYTD)}` + lineBreak;
      csv += lineBreak;
      csv += ',' + MonthNames.map(x => t(x, { ns: 'common' })) + lineBreak;

      dto.meterCategories.forEach(category => {
        csv += `${year}${!category.meterCategory ? '' : ` ${t(category.meterCategory, { ns: 'enums' }).toLowerCase()}`}${category.meterCategory === 'Unmetered' ? '' : ` ${t('Actual', { ns: 'common' }).toLowerCase()}`}, ${category.currentYearTotals.map(x => displayString(x))}` + lineBreak;
      });

      csv += `${year} ${t('SiteOverview.CSVExport.Total', { ns: 'molecules' })}, ${dto.monthlyTotalsThisYear.map(x => displayString(x))}` + lineBreak;

      csv += `${year} ${dto.isGasWidget ? t('Target', { ns: 'common' }).toLowerCase() : t('SiteOverview.CSVExport.TotalTarget', { ns: 'molecules' })}, ${dto.monthlyTargets.map(x => displayString(x))}` + lineBreak;

      dto.meterCategories.forEach(category => {
        csv += `${year - 1}${!category.meterCategory ? '' : ` ${t(category.meterCategory, { ns: 'enums' }).toLowerCase()}`}${category.meterCategory === 'Unmetered' ? '' : ` ${t('Actual', { ns: 'common' }).toLowerCase()}`}, ${category.previousYearTotals.map(x => displayString(x))}` + lineBreak;
      });

      csv += `${year - 1} ${t('SiteOverview.CSVExport.Total', { ns: 'molecules' })}, ${dto.monthlyTotalsLastYear.map(x => displayString(x))}` + lineBreak;

      csv += lineBreak;
    });

    return csv;
  }, [data, t]);

  const downloadFile = useCallback(async () => {
    const fileName = `${tenant.name}_${site.name}_${t('Utility', { ns: 'common' })}_${t('Summary', { ns: 'common' })}_${(new Date).getFullYear()}`;

    exportCSV(fileName, csvString);
    trackAction('export_excel', 'performance_utilities');
  }, [csvString, site, tenant, t, trackAction]);

  return (
    <div style={{ marginLeft: 'auto' }}>
      <Button
        secondary
        label={t('Export', { ns: 'common' })}
        onClick={() => downloadFile()}
        disabled={loading || data.length === 0}
      />
    </div>
  );
};

export default CSVExport;