import { useTheme } from 'styled-components';
import { getAbbreviatedMonthName } from '@utils/DateUtils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Chart, ChartData, ChartTypeRegistry } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { LineBarChartType } from '@components/charts/Chart.types';
import { constructChartOptions } from './chartOptions';
import { darken, lighten, transparentize } from 'polished';
import { isNaN } from 'lodash';
import { UtilityData } from '../../UtilitiesSummary';
import { CumulativeChartLegendStyle } from './Legend/ChartLegend';

// Merge arrays into one array by summing the values at each index position
const sumArrays = (arrays: number[][]) => {
  return arrays.reduce((acc, array) => acc.map((sum, i) => {
    if (isNaN(array[i])) {
      return sum;
    }

    if (isNaN(sum)) {
      return array[i];
    }

    return sum + array[i];
  }), new Array(arrays[0].length).fill(NaN));
}

// Sum up the values in an array by summing each value with the value at the previous index position 
const addUpArrayValues = (array: number[]) => {
  let sum = 0;
  return array.map(x => sum = (sum || 0) + (x ?? NaN));
}

export type YearlyChartDataset = {
  id: string;
  label: string;
  color: string;
  dataUnit: string;
  data: number[];
  legend: CumulativeChartLegendStyle;
}

type PropTypes = {
  data: UtilityData;
};

const useCumulativeChart = ({ data }: PropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart<keyof ChartTypeRegistry, number[], string>>();
  const [datasets, setDatasets] = useState<YearlyChartDataset[]>([]);

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    const currentYearColor = lighten(0.1, theme.palette.primary);
    const prevYearColor = lighten(0.3, theme.palette.secondary);
    const targetColor = darken(0.3, currentYearColor);

    const targetDataset = {
      id: 'Target',
      label: t('Target', { ns: 'common' }),
      dataUnit: data.unit,
      data: addUpArrayValues(data.monthlyTargets),
      type: LineBarChartType.Line,
      color: targetColor,
      borderColor: targetColor,
      fill: false,
      legend: CumulativeChartLegendStyle.Line
    };

    const currentYearDataset = {
      id: 'CurrentYearTotal',
      label: t('SiteOverview.TotalThisYear', { ns: 'molecules' }),
      dataUnit: data.unit,
      data: addUpArrayValues(sumArrays(data.meterCategories.map(x => x.currentYearTotals))),
      type: LineBarChartType.Line,
      color: currentYearColor,
      borderColor: currentYearColor,
      backgroundColor: transparentize(0.75, currentYearColor),
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBackgroundColor: transparentize(0, currentYearColor),
      legend: CumulativeChartLegendStyle.Dotted
    };

    const previousYearDataset = {
      id: 'PreviousYearTotal',
      label: t('SiteOverview.TotalLastYear', { ns: 'molecules' }),
      dataUnit: data.unit,
      data: addUpArrayValues(sumArrays(data.meterCategories.map(x => x.previousYearTotals))),
      type: LineBarChartType.Line,
      color: prevYearColor,
      borderColor: prevYearColor,
      borderDash: [10, 8],
      fill: false,
      legend: CumulativeChartLegendStyle.Dashed
    };

    const datasets = [targetDataset, previousYearDataset, currentYearDataset];
    setDatasets(datasets);

    return {
      labels: data.monthlyTargets.map((_, i) => getAbbreviatedMonthName(i + 1)),
      datasets: datasets
    }
  }, [data, theme, t]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');

    if (context) {
      const chart = new Chart(context, {
        type: 'bar',
        data: constructData(),
        options: constructChartOptions(theme)
      });

      setChart(chart);
      return () => chart.destroy();
    }
  }, [constructData, theme]);

  /**
   * Show/hide datasets from the chart as they are selected/deselected in the legend component.
   */
  const handleLegendChange = (hiddenDatasets: { [key: string]: string }) => {
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        dataset.hidden = !!hiddenDatasets[(dataset as unknown as YearlyChartDataset).id];
      });

      chart.update();
    }
  };

  return {
    canvasRef,
    datasets,
    handleLegendChange
  };
};

export default useCumulativeChart;