import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { Input } from '@components/Form';
import { validateEmail } from '@utils/StringUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';

interface IAlertEmailSettingsProps {
  emails: string[];
  onChange: (emails: string[]) => void;
}

const AlertEmailSettings = ({ emails, onChange }: IAlertEmailSettingsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const updateEmail = useCallback((updatedEmail: string, index: number) => {
    const clone = [...emails];
    clone[index] = updatedEmail;
    onChange(clone);
  }, [emails, onChange]);

  const removeEmail = useCallback((index: number) => {
    const clone = [...emails];
    clone.splice(index, 1);
    onChange(clone);
  }, [emails, onChange]);

  const addEmail = useCallback(() => {
    const clone = [...emails];
    clone.push('');
    onChange(clone);
  }, [emails, onChange]);

  return (
    <FlexColumn>
      {emails.map((email, i) => (
        <FlexRow key={i}>
          <Tooltip
            content={<TooltipContent>{t('RemoveEmail', { ns: 'common' })}</TooltipContent>}
            placement={TooltipPlacement.Right}
            hideTriangle
          >
            <CircleIcon
              icon={solid('xmark')}
              color={theme.palette.red}
              onClick={() => removeEmail(i)}
            />
          </Tooltip>

          <div style={{ flexGrow: 1, position: 'relative' }}>
            <Input
              type="email"
              value={email}
              onInput={(e) => updateEmail(e.currentTarget.value.trim(), i)}
            />

            {!validateEmail(email) &&
              <HelperMessage style={{ margin: '1px 4px' }}>
                {t('Email', { ns: 'validation' })}
              </HelperMessage>
            }
          </div>
        </FlexRow>
      ))}

      <FlexRow style={{ marginTop: '7px' }}>
        <Tooltip
          content={<TooltipContent>{t('AddEmail', { ns: 'common' })}</TooltipContent>}
          placement={TooltipPlacement.Right}
          hideTriangle
        >
          <CircleIcon
            icon={solid('plus')}
            color={theme.palette.primary}
            onClick={addEmail}
          />
        </Tooltip>
      </FlexRow>
    </FlexColumn>
  )
};

export default AlertEmailSettings;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const HelperMessage = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  position: absolute;
  top: 39px;
`;

const CircleIcon = styled(FontAwesomeIcon) <{ color: string }>`
  width: 12px;
  height: 12px;

  padding: 4px;
  border-radius: 50%;
  border: 1px solid ${p => p.color};
  color: ${p => p.color};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color: ${p => p.color};
  };
`;

const TooltipContent = styled.div`
  font-size: 14px;
  padding: 5px 3px;
`;