import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceType } from '@api/enums/SpaceType';
import { ICheckboxSelectGroup } from '@components/checkbox-select/CheckboxSelect.types';

const EsgExplorerSpaceTypeOptions: ICheckboxSelectGroup<SpaceType>[] = [
  {
    label: 'Apartment',
    icon: <FontAwesomeIcon icon={solid('house-building')} color='#00DED3' style={{ width: 15, height: 15 }} />,
    options: [
      { label: SpaceType.Studio, value: SpaceType.Studio },
      { label: SpaceType.OneBed, value: SpaceType.OneBed },
      { label: SpaceType.TwoBed, value: SpaceType.TwoBed },
      { label: SpaceType.ThreeBed, value: SpaceType.ThreeBed },
      { label: SpaceType.FourBed, value: SpaceType.FourBed },
      { label: SpaceType.DuplexTwoBed, value: SpaceType.DuplexTwoBed },
      { label: SpaceType.DuplexThreeBed, value: SpaceType.DuplexThreeBed },
      { label: SpaceType.DuplexFourBed, value: SpaceType.DuplexFourBed },
      { label: SpaceType.SharerTwoBed, value: SpaceType.SharerTwoBed },
      { label: SpaceType.SharerThreeBed, value: SpaceType.SharerThreeBed },
      { label: SpaceType.SharerFourBed, value: SpaceType.SharerFourBed },
      { label: SpaceType.SharerFiveBed, value: SpaceType.SharerFiveBed },
      { label: SpaceType.SharerSixBed, value: SpaceType.SharerSixBed },
      { label: SpaceType.SharerSevenBed, value: SpaceType.SharerSevenBed },
      { label: SpaceType.SharerEightBed, value: SpaceType.SharerEightBed },
      { label: SpaceType.LKD, value: SpaceType.LKD },
      { label: SpaceType.Bedroom, value: SpaceType.Bedroom },
      { label: SpaceType.Penthouse, value: SpaceType.Penthouse }
    ]
  },
  {
    label: 'Office',
    icon: <FontAwesomeIcon icon={solid('briefcase')} color='#FF821B' style={{ width: 15, height: 15 }} />,
    options: [
      { label: SpaceType.Boardroom, value: SpaceType.Boardroom },
      { label: SpaceType.MeetingRoom, value: SpaceType.MeetingRoom },
      { label: SpaceType.OpenPlan, value: SpaceType.OpenPlan },
      { label: SpaceType.PrivateOffice, value: SpaceType.PrivateOffice },
    ]
  },
  {
    label: 'Common',
    icon: <FontAwesomeIcon icon={solid('people-roof')} color='#FFB500' style={{ width: 15, height: 15 }} />,
    options: [
      { label: SpaceType.Bathroom, value: SpaceType.Bathroom },
      { label: SpaceType.Cinema, value: SpaceType.Cinema },
      { label: SpaceType.Cupboard, value: SpaceType.Cupboard },
      { label: SpaceType.Elevator, value: SpaceType.Elevator },
      { label: SpaceType.Gym, value: SpaceType.Gym },
      { label: SpaceType.Kitchen, value: SpaceType.Kitchen },
      { label: SpaceType.Library, value: SpaceType.Library },
      { label: SpaceType.Other, value: SpaceType.Other },
      { label: SpaceType.PrivateDining, value: SpaceType.PrivateDining },
      { label: SpaceType.Reception, value: SpaceType.Reception },
      { label: SpaceType.Toilet, value: SpaceType.Toilet },
      { label: SpaceType.Lounge, value: SpaceType.Lounge }
    ]
  }
];

export default Object.freeze(EsgExplorerSpaceTypeOptions);