import styled, { useTheme } from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { getConsumptionWidgetConfig } from '../helpers';
import { MetricType } from '@api/enums/MetricType';
import { ToggleOption } from '@components/core/Toggle';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { LocalisationType } from '@i18n//localisation';
import { Select } from '@components/select/Select';
import { CSSObjectWithLabel } from 'react-select';

const getOptions = (metricType: MetricType, localisation: LocalisationType) => {
  const { unit, conversion } = getConsumptionWidgetConfig(metricType, localisation);

  return [
    {
      label: unit,
      value: false
    },
    {
      label: conversion?.unit ?? '',
      value: true
    }
  ];
};

type PropTypes = {
  metricType: MetricType;
  conversions: { [key: string]: string };
  onChange: (conversions: { [key: string]: string }) => void;
};

const UnitToggle = ({ metricType, conversions, onChange }: PropTypes) => {
  const theme = useTheme();
  const [options, setOptions] = useState<ToggleOption<boolean>[]>([]);
  const [selected, setSelected] = useState<boolean>();
  const { localisation } = useLocalisation();

  useEffect(() => {
    const options = getOptions(metricType, localisation);
    setOptions(options);
    setSelected(!!conversions[metricType]);
  }, [metricType, conversions, localisation])

  const handleChange = useCallback((convert: boolean) => {
    setSelected(convert);

    const copy = { ...conversions };

    if (convert) {
      copy[metricType] = metricType;
    } else {
      delete copy[metricType];
    }

    onChange(copy);
  }, [metricType, conversions, onChange]);

  const customStyles = {
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      paddingLeft: 0,
      marginLeft: -4,
      color: theme.action.active,

      'svg': {
        width: 16,
        height: 16
      }
    } as CSSObjectWithLabel),
  };

  return (
    <Wrapper>
      <Select
        value={options.find(x => x.value === selected)}
        options={options}
        onChange={selected => selected && handleChange(selected.value)}
        borderColor='transparent'
        boxShadow='none'
        customStyles={customStyles}
        isSearchable={false}
      />
    </Wrapper>
  );
};

export default UnitToggle;

const Wrapper = styled.div`
  width: fit-content;
  margin: 0 0 8px -5px;
`;